import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaPinterest, FaWhatsapp, FaCopy } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import anchorme from 'anchorme';
import parse from 'html-react-parser';
// import { MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
import axiosInstance from '../../main/axios';
import { URLS } from '../../library/common/constants/UrlConstants';
import { fetchFromStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import Config from '../../utility/Config';
import { EventEmitter } from 'library/common/constants/event';
import { setApiMessage, getAppLinkByMobileOS } from '../../library/common/constants/function';
import EventCard from '../../library/common/components/EventCard';
import EventTabWrapper from './TabWrapper';
import PageLoader from '../../library/common/components/PageLoader';
import NoOrgModal from '../../modules/Events/CreateEvent/NoOrganisationModal';
import LogoutModal from './Frames/Raffle/LogoutModal';
import { SocketContext } from '../../main/context/socket';
import { images } from '../../library/common/constants/ImageConstants';
import { ReactComponent as SocialFacebook } from '../../resources/images/social_facebook.svg';
import { ReactComponent as SocialInstagram } from '../../resources/images/social_instagram.svg';
import { ReactComponent as SocialYoutube } from '../../resources/images/social_youtube.svg';
import { ReactComponent as SocialTwitter } from '../../resources/images/social_twitter.svg';
import { ReactComponent as SocialLinkedin } from '../../resources/images/social_linkedin.svg';
import { ReactComponent as SocialPintrest } from '../../resources/images/social_pintrest.svg';
import { ReactComponent as SocialCustom } from '../../resources/images/social_custom.svg';
import EventCountDownDetails from './EventCountDownDetails';
import './styles.scss';

const EventDetails = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [eventOutfit, setEventOutfit] = useState();
  const [loading, setloader] = useState(false);
  const [isSocialMedia, setSocialMediaFlag] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [eventFeatures, setEventFeatures] = useState('');
  const [isNoOrgToggle, setIsNoOrgToggle] = useState(false);
  const [isloginToggle, setLoginToggle] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [modalErrorText, setModalErrorText] = useState('');

  const params = useParams();

  const appInfo = {
    imageUrl: images.app_logo,
    name: 'Oktion',
    publisher: 'DigiGround Pty Ltd',
    infoText: 'Free',
    linkButtonText: 'VIEW',
    linkUrl: '',
  };

  appInfo.linkUrl = getAppLinkByMobileOS();

  var eventDetails = fetchFromStorage(identifiers.eventDetails);
  var eventId = params?.id !== undefined ? params.id : eventDetails?.id;

  useEffect(() => {
    var checkForUserLogin = fetchFromStorage(identifiers.token);
    if (checkForUserLogin !== null) {
      getEventDetailsByToken();
    } else {
      getEventDetailsById();
    }
    // userSocket.socket.on('currentBid', data => {
    //   EventEmitter.dispatch('socketUpdateBiddingData', data);
    // });
    userSocket.socket.on('EventDetail', data => {
      EventEmitter.dispatch('socketUpdateEventDetailsData', data);
    });
    EventEmitter.subscribe('socketUpdateEventDetailsData', event => {
      Number(params?.id) === event?.events?.id && setEventOutfit(event?.events && event?.events);
    });
    EventEmitter.subscribe('activeTabData', event => setActiveTab(event));
    EventEmitter.subscribe('setFeaturesData', event => setEventFeatures(event));
    Object.keys(params).length === 0 && navigate('/');
  }, []);

  // Fetches the social links list based on organisation ID
  const getSocialLinksList = async orgID => {
    setloader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getsocialLInks(orgID));
      if (status === 200 || status === 201) {
        // Filter non-empty social links from both default and custom lists
        let socialLinks = data?.data?.OrganisationSocialLinksList?.filter(item => item?.linkUrl !== '');
        let otherLinks = data?.data?.OrganisationCustomSocialLinksList?.filter(item => item?.linkUrl !== '');

        setSocialData([...socialLinks, ...otherLinks]); // Merges and updates the state with social links data
      }
      setloader(false);
    } catch (err) {
      setloader(false);
    }
  };

  // Returns the appropriate social media icon component based on the type
  const getSocialIcon = type => {
    if (type === 'facebook') {
      return <SocialFacebook />;
    } else if (type === 'instagram') {
      return <SocialInstagram />;
    } else if (type === 'youtube') {
      return <SocialYoutube />;
    } else if (type === 'twitter') {
      return <SocialTwitter />;
    } else if (type === 'linkedin') {
      return <SocialLinkedin />;
    } else if (type === 'pinterest') {
      return <SocialPintrest />;
    } else if (type === 'custom') {
      return <SocialCustom />;
    }
  };

  // Fetches event details based on event ID
  const getEventDetailsById = async () => {
    setloader(true);
    const previewStatus = location?.state?.previewStatus; // Fetches preview status from location state
    var prevStatus = previewStatus !== undefined ? previewStatus : 0; // Sets preview status
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
      if (status === 200 || status === 304) {
        // Checks authorization
        if (data?.msg === 'You are not authorized') {
          setModalErrorText(''); // Clears modal error text
          setIsNoOrgToggle(true); // Opens modal for unauthorized users
        } else {
          var tempData = data?.data?.events; // Event data

          // Fetches social links based on organisation ID
          getSocialLinksList(tempData?.organisation?.id);

          // Prepares event display data based on preview props
          tempData = {
            ...tempData,
            primaryColour: props?.previewPropsValue?.hexCode[0],
            primaryTextColour: props?.previewPropsValue?.hexCode[1],
            secondaryColour: props?.previewPropsValue?.hexCode[2],
            secondaryTextColour: props?.previewPropsValue?.hexCode[3],
            background: props?.previewPropsValue?.background,
            pictureFit: props?.previewPropsValue?.backgroundSize,
            backgroundProperty: props?.previewPropsValue?.backgroundProperty,
          };

          setEventOutfit(tempData);
        }
      }
      setloader(false);
    } catch (err) {
      setloader(false);
      // Handles specific error codes
      if (err?.response?.data?.errorCode === 'ERR_EVENT_SUSPENDED_CANCELLED') {
        setIsNoOrgToggle(true); // Opens modal for cancelled or suspended event
        setModalErrorText(err?.response?.data?.msg); // Sets error message
      } else if (err?.response?.data?.msg === 'No such event exists') {
        navigate('/'); // Redirects to homepage if event doesn't exist
      }
    }
  };

  // Scrolls to top and shows success message when link is copied
  const handleCopyLink = async => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setApiMessage('success', `${OKTION_VARIABLE?.OKTION_COPIED}!`);
  };

  // Fetches event details using a token for authorization
  const getEventDetailsByToken = async () => {
    var token = fetchFromStorage(identifiers.token); // Retrieves token from storage
    const previewStatus = location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    setloader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByToken(eventId, token?.token, prevStatus));
      setloader(false);
      if (status === 200 || status === 304) {
        // Handles unauthorized access
        if (data?.msg === 'You are not authorized') {
          setModalErrorText(''); // Clears modal error text
          setIsNoOrgToggle(true); // Opens unauthorized access modal
        } else {
          var tempData = data?.data?.events;
          getSocialLinksList(tempData?.organisation?.id); // Fetches organisation's social links

          // Handles whitelabel (custom branding) data
          if (props.whitelabel) {
            tempData = {
              ...tempData,
              primaryColour: props?.previewPropsValue?.hexCode[0],
              primaryTextColour: props?.previewPropsValue?.hexCode[1],
              secondaryColour: props?.previewPropsValue?.hexCode[2],
              secondaryTextColour: props?.previewPropsValue?.hexCode[3],
              background: props?.previewPropsValue?.background,
              pictureFit: props?.previewPropsValue?.backgroundSize,
              backgroundProperty: props?.previewPropsValue?.backgroundProperty,
            };
            setEventOutfit(tempData);
          } else {
            setEventOutfit(data?.data?.events);
          }
        }
      }
    } catch (err) {
      setloader(false);
      // Handles specific error codes
      if (err?.response?.data?.errorCode === 'ERR_EVENT_SUSPENDED_CANCELLED') {
        setModalErrorText(err?.response?.data?.msg); // Sets error message for suspended/cancelled event
        setIsNoOrgToggle(true); // Opens modal
      } else if (err?.response?.data?.msg === 'No such event exists') {
        navigate('/'); // Redirects to homepage if event doesn't exist
      }
    }
  };

  // No organisation modal
  const toggleNoOrgModal = () => {
    setIsNoOrgToggle(!isNoOrgToggle);
  };

  // Handles navigation to organiser profile page when click on contact
  const handleContactBtn = () => {
    navigate(`/organiser-profile/${eventOutfit?.organisation?.id}`, {
      state: {
        eventId: params?.id,
        orgId: eventOutfit?.organisation?.id,
      },
    });
  };

  // Toggles the social media flag state
  const handleSocailShareBtn = () => {
    setSocialMediaFlag(!isSocialMedia);
  };

  // Handles item click and reloads the page
  const handleItemClick = event => {
    window.location.reload(false);
    navigate(`/event-details/${event?.id}`);
  };

  // Handles follow/unfollow functionality based on the isFollow state
  const handleFollowUnfollow = isFollow => {
    if (!props?.isLoggedIn) {
      setLoginToggle(true); // Toggles login modal if user is not logged in
    }
    if (props?.isLoggedIn) {
      handleAddToFollow(isFollow); // Calls function to follow/unfollow event
    }
  };

  // Adds or removes event from follow list based on the isFollow parameter
  const handleAddToFollow = async isFollow => {
    try {
      if (isFollow === false) {
        setloader(true);
        const { status } = await axiosInstance.post(URLS.eventFollow(eventId)); // API call to follow event
        if (status === 200 || status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_EVENT_ADDED); // Shows success message for follow
          getEventDetailsByToken(); // Refreshes event details
        }
        setloader(false);
      } else {
        setloader(true);
        const { status } = await axiosInstance.post(URLS.eventUnFollow(eventId)); // API call to unfollow event
        if (status === 200 || status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST); // Shows success message for unfollow
          getEventDetailsByToken(); // Refreshes event details
        }
        setloader(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setApiMessage('error', err?.response?.data?.msg);
        setloader(false);
      }
    }
    setloader(false);
  };

  // Handle logout modal if you are not login
  const handleLoginModal = () => {
    setLoginToggle(!isloginToggle);
  };

  // Handle redirect to login page
  const handleModalLoginBtn = () => {
    navigate('/login', {
      redirectTo: 'event-details',
      eventId: params?.id,
    });
  };

  // Determine the background color based on the location state
  var backColor =
    location?.state?.hexCode?.background_picture === false ? location?.state?.hexCode?.background_color : '';

  // Define the default background color based on the eventOutfit object
  var defBackColoc = eventOutfit?.background === 'color' ? eventOutfit?.backgroundProperty : '';

  // Determine the background picture based on the location state
  var backPicture = location?.state?.background === 'picture' ? `url(${location?.state?.backgroundProperty})` : '';

  // Define the default background picture based on the eventOutfit object
  var defPicture = eventOutfit?.background === 'picture' ? `url(${eventOutfit?.backgroundProperty})` : '';

  return (
    <div
      className="eventDetails home-container default-color"
      style={{
        background: props?.whitelabel === true ? backPicture : defPicture,
        backgroundColor: props?.whitelabel === true ? backColor : defBackColoc,
        backgroundSize: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : 'contain',
        backgroundPosition: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : '',
        backgroundRepeat: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : '',
      }}>
      <div className="container">
        {/* <MobileView>
          <AppBanner appInfo={appInfo} position="bottom" onClose={() => {}} hidden={false} style={{ zIndex: 15 }} />
        </MobileView> */}

        <Row>
          <Col sm="12">{loading && <PageLoader />}</Col>{' '}
          <Col sm="12">
            <div>
              <div className="event-image-wrapper mt-5">
                <Avatar
                  src={
                    eventOutfit?.eventImages !== null &&
                    identifiers?.imageUrlConstant + eventOutfit?.eventImages[0]?.name
                  }
                  alt=""
                />
              </div>
            </div>
            <div
              className="flow"
              style={{
                pointerEvents: props?.whitelabel === true && 'none',
                opacity: props?.whitelabel === true && '0.7',
              }}>
              <div onClick={() => handleFollowUnfollow(eventOutfit?.isFollow)}>
                {eventOutfit?.isFollow && eventOutfit?.isFollow === true ? (
                  <>
                    <button
                      className="badge-wrapper p-0"
                      disabled={props?.whitelabel === true ? true : false}
                      style={{
                        backgroundColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                        borderColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                      }}>
                      <BookmarkIcon
                        style={{
                          color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '#0080FF',
                          width: '30px',
                          height: '40px',
                        }}
                      />
                    </button>
                  </>
                ) : (
                  <>
                    <div
                      className="badge-wrapper"
                      style={{
                        pointerEvents: props?.whitelabel === true && 'none',
                        opacity: props?.whitelabel === true && '0.4',
                      }}>
                      <BookmarkIcon style={{ color: '#000', width: ' 30px', height: '40px' }} />
                    </div>
                  </>
                )}
              </div>
              <div
                className="badge-wrapper mb-3"
                onClick={handleSocailShareBtn}
                style={{
                  backgroundColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                  borderColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                }}>
                <img src={images.networkIcon} alt="Social share" />
              </div>
              {isSocialMedia && (
                <div className="social-media-main">
                  <div className="mb-3">
                    <IconButton style={{ background: '#3B5998' }}>
                      <FacebookShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        className="socialMediaIcons">
                        <FaFacebookF fontSize="inherit" size="20px" />
                      </FacebookShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#55ACEE' }}>
                      <TwitterShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        className="socialMediaIcons">
                        <FaTwitter fontSize="inherit" size="20px" />
                      </TwitterShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#007bb5' }}>
                      <LinkedinShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        className="socialMediaIcons">
                        <FaLinkedinIn fontSize="inherit" size="20px" />
                      </LinkedinShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#cb2027' }}>
                      <PinterestShareButton
                        url={`https://pinterest.com/pin/create/button/?url=${Config.socketUrl}event-details/${eventId}&media=&description=`}
                        color="#BD081C"
                        size="40px"
                        description="Check out this free React Component by @stephanwozniak, made for easily sharing pages on social media sites!"
                        media={`${Config.socketUrl}event-details/${eventId}`}
                        className="socialMediaIcons">
                        <FaPinterest fontSize="inherit" size="20px" />
                      </PinterestShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#25d366' }}>
                      <WhatsappShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        className="socialMediaIcons">
                        <FaWhatsapp fontSize="inherit" size="20px" />
                      </WhatsappShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#ffc629' }}>
                      <button
                        className="copy_link_button"
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                          handleCopyLink();
                        }}>
                        <FaCopy fontSize="inherit" size="20px" />
                      </button>
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div className="bg-white content mb-3">
              <h2 className="res-orgcard-name text-center hide-sm hide-md hide-lg hide-xl">
                {eventOutfit &&
                  eventOutfit?.eventName != null &&
                  parse(
                    anchorme({
                      input: (eventOutfit?.eventName).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
              </h2>
              <h2 className="text-center pt-4 pb-2 hide-xs">
                {eventOutfit &&
                  eventOutfit?.eventName != null &&
                  parse(
                    anchorme({
                      input: (eventOutfit?.eventName).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
              </h2>
              <div className="text-center">
                {eventOutfit?.organisation?.imagePath && eventOutfit?.organisation?.imagePath !== null ? (
                  <img
                    className="imgWidth"
                    src={
                      props?.previewPropsValue?.hexCode?.hasOwnProperty('Logo_name') === true &&
                      props?.previewPropsValue?.hexCode['Logo_name'] !== ''
                        ? props?.previewPropsValue?.hexCode['Logo_name']
                        : eventOutfit?.companyLogo !== null
                          ? eventOutfit?.companyLogo
                          : identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath
                    }
                    alt={eventOutfit?.organisation?.name}
                    onError={e => ((e.target.src = images?.logo), (e.target.className = 'imgWidth error-bg-img'))}
                  />
                ) : (
                  <div className="square"></div>
                )}
              </div>
              <h5 className="res-orgby mt-2 fw-normal text-center pb-3 border-bottom mb-4 hide-sm hide-md hide-lg hide-xl">
                {eventOutfit ? `${OKTION_VARIABLE?.OKTION_BY} ${eventOutfit?.organisation?.name}` : ''}
              </h5>
              <h5 className="mt-2 fw-normal text-center pb-4  hide-xs">
                {eventOutfit ? `${OKTION_VARIABLE?.OKTION_BY} ${eventOutfit?.organisation?.name}` : ''}
              </h5>
              {socialData?.length > 0 && (
                <ul className="social-icon-wrap border-bottom mb-4 pb-4">
                  {socialData?.map((item, i) => {
                    return (
                      <li key={i}>
                        <a href={item?.linkUrl} target="_blank">
                          {getSocialIcon(item?.linkType)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
              <EventCountDownDetails
                activeTab={activeTab}
                eventFeatures={eventFeatures}
                eventOutfit={eventOutfit}
                loading={loading}
              />
              <EventTabWrapper
                eventDetails={eventOutfit}
                preview_color={props.preview_color}
                featureName={location?.state?.featureName}
                whitelabel={props?.whitelabel}
              />
              <Row>
                <Col sm="12">
                  <div className="text-center mt-3 bottomSection">
                    <div className="text-center">
                      {eventOutfit?.organisation?.imagePath && eventOutfit?.organisation?.imagePath !== null ? (
                        <img
                          className="imgWidth"
                          src={
                            props?.previewPropsValue?.hexCode?.hasOwnProperty('Logo_name') === true &&
                            props?.previewPropsValue?.hexCode['Logo_name'] !== ''
                              ? props?.previewPropsValue?.hexCode['Logo_name']
                              : eventOutfit?.companyLogo !== null
                                ? eventOutfit?.companyLogo
                                : identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath
                          }
                          alt={eventOutfit?.organisation?.name}
                          onError={e => ((e.target.src = images?.logo), (e.target.className = 'imgWidth error-bg-img'))}
                        />
                      ) : (
                        <div className="square"></div>
                      )}
                    </div>
                    <h5 className="res-evnt-org-name fw-normal text-center mb-2 hide-sm hide-md hide-lg hide-xl">
                      {eventOutfit?.organisation?.name}
                    </h5>
                    <h5 className="mt-2 fw-normal text-center mb-2 hide-xs">{eventOutfit?.organisation?.name}</h5>
                    <div className="p-15">
                      {eventOutfit?.organisation?.aboutTheOrganisation &&
                        parse(
                          anchorme({
                            input: (eventOutfit?.organisation?.aboutTheOrganisation).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </div>
                    <Button
                      className="main-button mb-3 mt-3 h-60 fs-20 fw-500 contact-btn"
                      onClick={handleContactBtn}
                      disabled={props.whitelabel === true ? true : false}
                      style={{
                        backgroundColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                        borderColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                        color: eventOutfit?.primaryTextColour ? eventOutfit?.primaryTextColour : '',
                      }}>
                      {OKTION_VARIABLE?.OKTION_CONTACT}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {props.whitelabel !== true ? (
          <div className="pb-4">
            <h4 className="text-center pb-3 pt-3 fw-500">{OKTION_VARIABLE?.OKTION_OTHER_EVENT_LIKE}</h4>
            <Row className="justify-content-center">
              {eventOutfit?.similarEventFeatureDetails &&
                eventOutfit?.similarEventFeatureDetails?.map((item, index) => (
                  <Col
                    key={index}
                    lg="3"
                    md="4"
                    sm="12"
                    className="event-wrapper mb-2"
                    onClick={() => handleItemClick(item)}>
                    <EventCard
                      key={index}
                      image={item?.eventImages}
                      types={item?.types}
                      event={item}
                      currency={eventOutfit?.currency?.abbreviation}
                      isManaged
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
      <NoOrgModal
        isFromPublic={true}
        isToggle={isNoOrgToggle}
        toggle={toggleNoOrgModal}
        handleClick={() => navigate('/')}
        modalErrorText={modalErrorText}
      />{' '}
      <LogoutModal isToggle={isloginToggle} toggle={handleLoginModal} handleClick={handleModalLoginBtn} />
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(EventDetails);
