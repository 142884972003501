import React, { useContext } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import { images } from '../../../../library/common/constants/ImageConstants';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { SocketContext } from 'main/context/socket';

const TicketDataModal = ({ isToggle, toggle, item, eventDetails, raffleDrawDate, ticket, isBarcode }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const qrString = JSON.stringify({
    ticketNumber: item?.ticketNumber,
    ticketId: item?.id,
  });
  const barcodeForRaffleTicket = JSON.stringify({
    eventName: eventDetails?.eventName,
    eventId: eventDetails?.id,
    eventDate: moment(eventDetails?.startDate).format('DD/MM/YYYY hh:mm A'),
    raffleTicketNumber: item?.ticketNumber,
    raffleTicketId: item?.id,
    raffleDrawDate: moment(raffleDrawDate, moment.ISO_8601).format('DD/MM/YYYY hh:mm A'),
  });

  return (
    <div>
      <Modal className="modal-dialog add-price-modal ticket-data-modal" isOpen={isToggle}>
        <div className="text-end close-rs-ticketmodal">
          <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          <div className="">
            <Row>
              <Col lg="3" md="3">
                <div className="image-wrapper">
                  <img
                    className="w-100"
                    src={
                      eventDetails?.eventImages !== null &&
                      identifiers.imageUrlConstant + eventDetails?.eventImages[0].name
                    }
                    alt={OKTION_VARIABLE?.OKTION_EVENT_IMAGE}
                  />
                </div>
              </Col>
              <Col lg="6" md="5">
                <div className="rst-tckt-mdl">
                  <h4>{OKTION_VARIABLE?.OKTION_EVENT_TITLE_CAPITAL}</h4>
                  <div>
                    {eventDetails?.eventName &&
                      parse(
                        anchorme({
                          input: (eventDetails?.eventName).toString().replace(/\n/g, '<br/>'),
                          options: {
                            attributes: {
                              target: '_blank',
                              class: 'detected',
                            },
                          },
                        }),
                      )}
                  </div>
                  {ticket && (
                    <>
                      <h4>{OKTION_VARIABLE?.OKTION_TICKET_TITLE_CAPITAL} </h4>
                      <p> {item?.ticketName}</p>
                    </>
                  )}
                  <h4>
                    {!ticket ? OKTION_VARIABLE?.OKTION_RAFFLE_DRAW_DATE_TIME : OKTION_VARIABLE?.OKTION_WHEN_CAPITAL}
                  </h4>
                  <p>
                    {raffleDrawDate
                      ? raffleDrawDate
                      : moment(eventDetails?.venueStartDate != null && eventDetails?.venueStartDate).format(
                          'DD/MM/YYYY hh:mm A',
                        )}
                  </p>{' '}
                  <h4>{OKTION_VARIABLE?.OKTION_TICKET_NUMBER} </h4>
                  <p>{item?.ticketNumber}</p>
                </div>
              </Col>
              <Col lg="3" md="4">
                {ticket ? (
                  <QRCode size={150} value={qrString} />
                ) : (
                  <div className="barcode-svg" style={{ margin: '40px 0' }}>
                    <Barcode
                      value={barcodeForRaffleTicket}
                      width={2}
                      height={480}
                      format="CODE128"
                      displayValue={false}
                    />
                  </div>
                )}{' '}
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TicketDataModal;
