import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import Slider from 'react-slick';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import TicketsList from './TicketsList';
import EventTimeDetails from '../EventTimeDetails';
import TicketsCard from '../../../UserProfile/Frame/ContributionHistory/Frame/TicketsCard';
import LogoutModal from '../Raffle/LogoutModal';
import SendTicketModal from './SendTicketModal';
import TicketDataModal from './TicketDataModal';
import TicketPrintComponent from './TicketPrintComponent';
import { images } from '../../../../library/common/constants/ImageConstants';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';
import axiosInstance from '../../../../main/axios';
import { fetchFromStorage, saveToStorage } from 'utility';
import { setApiMessage } from 'library/common/constants/function';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles.scss';

const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

const Tickets = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const componentRef = useRef();
  const params = useParams();

  const [count, setCount] = useState({});
  const [isToggle, setToggle] = useState(false);
  const [isToggleValue, setToggleValue] = useState(false);
  const [isToggleModal, setToggleModalValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featureData, setFeatureData] = useState([]);
  const [ticketItemData, setticketItemData] = useState('');
  const [ticketCartlist, setTicketCartlist] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [cartItem, setClickItemData] = useState();
  const [size, setSize] = useState(window.innerWidth);

  var eventId = params?.id;

  useEffect(() => {
    // Handle window resize
    const updateSize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Calculate slides to show based on screen size
  const getSlideCount = size => {
    if (size > 1279) return 4;
    if (size > 959) return 3;
    if (size > 599) return 2;
    return 1;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: getSlideCount(size),
    slidesToScroll: 1,
  };

  const userDetail = fetchFromStorage(identifiers?.userDetail);

  // Initialize ticket counts
  useEffect(() => {
    var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    var tempObj = {};
    ticektFeatureData?.map((item, index) => {
      Object.assign(tempObj, { [`count${index}`]: 0 });
    });
    setCount(tempObj);
  }, [props?.eventFeatures]);

  // Fetch ticket data when user is logged in
  useEffect(() => {
    props?.isLoggedIn && fetchTicketPurchase();
  }, [props?.isLoggedIn]);

  // fetch tickets list
  const fetchTicketPurchase = async () => {
    try {
      setLoading(true);
      var TICKET_PURCHASE_DATA = [];
      const { status, data } = await axiosInstance.get(URLS.getTicketPurchase(eventId, false));
      if (status === 200 || status === 304) {
        TICKET_PURCHASE_DATA = data?.data?.tickets?.map(item => {
          var userName =
            item?.user?.firstName !== null && item?.user?.lastName !== null
              ? `${item?.user?.firstName} ${item?.user?.lastName}`
              : item?.user?.firstName !== null
                ? item?.user?.firstName
                : item?.user?.lastName !== null
                  ? item?.user?.lastName
                  : '';
          return {
            name: userName,
            amount: item?.user?.phoneNumber,
            toggleBundle: true,
            status: '',
            email: item?.user?.email,
            ticketNumber: item?.ticketNumber,
            id: item?.id,
            ticketName: item?.ticketName,
          };
        });
      }
      setLoading(false);
      setTicketCartlist(data?.data?.tickets);
      setTicketData(TICKET_PURCHASE_DATA);
    } catch (err) {
      setLoading(false);
    }
  };

  // Handle incrementing the ticket count
  const handleAddTicketCount = (name, index) => {
    var formData = { ...count, [name]: count[`count${index}`] + 1 };
    setCount({ ...count, [name]: count[`count${index}`] + 1 });

    var ticektFeatureData = props?.eventFeatures?.filter(item => item.type === 'ticket');
    var TICKER_FEATURE_DATA = [];
    TICKER_FEATURE_DATA = ticektFeatureData?.map((item, index) => {
      return {
        ...item,
        count: formData[`count${index}`],
      };
    });
    setFeatureData(TICKER_FEATURE_DATA);
  };

  // Handle decrementing the ticket count
  const handleMinusTicketCount = (name, index) => {
    if (count[`count${index}`] > 0) {
      var formData = { ...count, [name]: count[`count${index}`] - 1 };
      setCount({ ...count, [name]: count[`count${index}`] - 1 });

      var ticektFeatureData = props?.eventFeatures?.filter(item => item.type === 'ticket');
      var TICKER_FEATURE_DATA = [];
      TICKER_FEATURE_DATA = ticektFeatureData?.map((item, index) => {
        return {
          ...item,
          count: formData[`count${index}`],
        };
      });
      setFeatureData(TICKER_FEATURE_DATA);
    }
  };

  // single item data set
  const handleonItemClick = item => {
    setticketItemData(item);
    setToggleModalValue(true);
  };

  // Handle input change for the ticket count input field
  const handleInputChange = event => {
    const value = parseInt(event.target.value);
    const name = event.target.name;
    var formData = { ...count, [name]: value };
    setCount({ ...count, [name]: value });

    var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    var TICKER_FEATURE_DATA = [];
    TICKER_FEATURE_DATA = ticektFeatureData?.map((item, index) => {
      return {
        ...item,
        count: formData[`count${index}`],
      };
    });
    setFeatureData(TICKER_FEATURE_DATA);
  };

  // Handle guest user or invalid feature scenarios
  const handleGuestCheckout = async () => {
    var guestCheckoutArray = fetchFromStorage(identifiers?.guestcheckoutdata);
    setLoading(true);
    var senData = [];
    var TICKET_DATA = featureData?.filter(item => item?.count !== 0);
    senData =
      TICKET_DATA &&
      TICKET_DATA?.map(item => {
        return {
          type: 'ticket',
          quantity: item.count,
          eventId: item.eventId,
          referenceId: null,
          purchaseReferenceId: item.id,
          isRaffleTicket: false,
        };
      });

    let finalOldCart = guestCheckoutArray?.map(item => {
      return {
        type: item?.type,
        quantity: item?.quantity,
        eventId: item?.eventId,
        referenceId: item?.referenceId,
        purchaseReferenceId: item?.purchaseReferenceId,
        isRaffleTicket: item?.isRaffleTicket,
        donationAmount: item?.donationAmount,
      };
    });

    let filterguestCheckoutArray = {
      cartArray: senData,
      oldCart: Array.isArray(finalOldCart) ? finalOldCart : [],
    };

    try {
      const { status } = await axiosInstance.post(URLS.isValidFeture, filterguestCheckoutArray);
      if (status === 201 || status === 200) {
        setLoading(false);
        if (!props?.isLoggedIn) {
          setToggle(true);
        }
        handleSetCartData();
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err?.response?.data?.msg);
    }
  };

  // Handle ticket addition to cart for logged-in users
  const handleAddToCart = async () => {
    if (props?.isLoggedIn && userDetail?.userType === 'normal') {
      setLoading(true);
      var senData = [];
      var TICKET_DATA = featureData?.filter(item => item?.count !== 0);
      senData =
        TICKET_DATA &&
        TICKET_DATA?.map(item => {
          return {
            type: 'ticket',
            quantity: item?.count,
            eventId: item?.eventId,
            referenceId: null,
            purchaseReferenceId: item?.id,
            isRaffleTicket: false,
          };
        });

      try {
        const { status } = await axiosInstance.post(URLS.addToCart, senData);
        if (status === 201 || status === 200) {
          setLoading(false);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventId,
            },
          });
        }
      } catch (err) {
        setLoading(false);
        setApiMessage('error', err?.response?.data?.msg);
      }
    } else {
      handleGuestCheckout();
    }
  };

  // Set checkout cart data
  const handleSetCartData = () => {
    var TICKET_DATA = featureData.filter(item => item?.count !== 0);
    let CartData =
      TICKET_DATA &&
      TICKET_DATA?.map((item, index) => {
        var ticketValue = item?.eventFeatureOptions.filter((item, index) => item.key === 'priceperticket');
        return {
          type: 'ticket',
          quantity: item.count,
          referenceId: null,
          subscriptionName: null,
          subscriptionPrice: null,
          name: item?.featureName,
          amount: ticketValue[0]?.value,
          purchaseReferenceId: item?.id,
          isRaffleTicket: false,
          donationAmount: null,
          eventId: item?.eventId,
          currency: props?.eventDetails?.currency?.abbreviation,
          symbol: props?.eventDetails?.currency?.symbol,
          event: props?.eventDetails,
          eventName: props?.eventDetails?.eventName,
          digiCommission: 0,
          itemDetails: item,
          cardCharedAmount: 0,
          total: ticketValue[0]?.value * item?.count,
        };
      });

    setClickItemData(CartData);
    if (props?.isLoggedIn && userDetail?.userType === 'guest') {
      updateGuestCardList(CartData);
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    }
  };

  // Update guest card list and dispatch event for updating guest checkout
  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  // Handle login redirect
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'ticket',
        eventId: eventId,
      },
    });
  };

  var ticektFeatureData = props?.eventFeatures?.filter(item => item?.type === 'ticket');

  const venueAddress = [
    props?.eventDetails?.venue?.addressLine1,
    props?.eventDetails?.venue?.addressLine2 + ',',
    <br />,
    props?.eventDetails?.venue?.city + ' ',
    props?.eventDetails?.venue?.state + ' ',
    props?.eventDetails?.venue?.country + ' ',
    props?.eventDetails?.venue?.postCode,
  ];

  const currency = props?.eventDetails?.currency?.abbreviation;
  const currencysymbol = props?.eventDetails?.currency?.symbol;
  let flag = featureData.every(i => {
    return i?.count === 0;
  });

  var findLength = ticketCartlist?.length > 8 && (ticketCartlist?.length - 8) / 10;
  var lengthArray = [];
  var temp = 0;
  for (var i = 0; i < findLength + 1; i++) {
    var start = temp;
    var end = i === 0 ? start + 8 : start + 10;
    temp = end;
    var obj = {
      start: start,
      end: end,
    };
    lengthArray.push(obj);
  }

  let DATE_NOW = moment(new Date()).format('YYYY-MM-DD HH mm');
  let EVENT_END_DATE = moment(props?.eventDetails?.venueEndDate).format('YYYY-MM-DD HH mm');

  let isVenueEndDateDisable = DATE_NOW >= EVENT_END_DATE;

  return (
    <div className="res-org-tickets">
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="m-2">
          {lengthArray?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <TicketPrintComponent
                  ticketCartlist={ticketCartlist.slice(item?.start, item?.end)}
                  eventDetails={props?.eventDetails}
                  venueAddress={venueAddress}
                  timeZone={props?.timeZone}
                  isEventDetailsShow={index === 0 ? true : false}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {props?.isLoggedIn && ticketCartlist.length > 0 && (
        <div className="d-flex justify-content-between align-items-center pt-5">
          <h4 className="">{OKTION_VARIABLE?.OKTION_YOUR_TICKETS}</h4>
          <div className="d-flex">
            <p className="fs-16 fw-500 cursor-pointer" onClick={() => setToggleValue(true)}>
              {OKTION_VARIABLE?.OKTION_TICKET_SENDTICKET}
            </p>
            <ReactToPrint
              pageStyle={pageStyle}
              trigger={() => <p className="fs-16 fw-500 ps-4 cursor-pointer hide-xs">Print Tickets</p>}
              content={() => componentRef.current}
            />
          </div>
        </div>
      )}
      {ticketCartlist?.length !== 0 && (
        <Slider {...settings}>
          {ticketCartlist.map((item, index) => (
            <React.Fragment key={index}>
              <TicketsCard
                isBarcode={true}
                isTicket={true}
                ticketData={item}
                showImage={true}
                handleItemClick={handleonItemClick}
                imageUrl={props?.eventDetails?.eventImages[0].name}
              />
            </React.Fragment>
          ))}
        </Slider>
      )}
      <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_TICKET_TITLE}</h4>
      <Row className="event-ticket-main">
        <Col lg="9" md="8" sm="12">
          {ticektFeatureData?.map((item, index) => (
            <div key={index} className={item?.isActive === false ? 'blur-section ticket-item' : 'ticket-item'}>
              <TicketsList
                item={item}
                index={`count${index}`}
                handleInputChange={handleInputChange}
                count={count}
                handleAdd={handleAddTicketCount}
                handleMinus={handleMinusTicketCount}
                loading={loading}
                itemNumber={index}
                currency={currency}
                currencySymbol={currencysymbol}
                eventDetails={props?.eventDetails}
                whitelabel={props?.whitelabel}
                isVenueEndDateDisable={isVenueEndDateDisable}
              />
            </div>
          ))}
          {!flag && (
            <Button
              onClick={handleAddToCart}
              className="main-button btn-block mt-2 bigButton"
              style={{
                backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
              }}
              disabled={isVenueEndDateDisable || props?.whitelabel === true ? true : false}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          )}
        </Col>
        <Col lg="3" md="4" sm="12" className="mb-3">
          {props?.timeZone !== undefined &&
            (props?.eventDetails?.location === 1 || props?.eventDetails?.location === 3) && (
              <EventTimeDetails
                img={images.watch}
                title={OKTION_VARIABLE?.OKTION_LEFTFILTER_EVENTDATE}
                startDate={moment(props?.eventDetails?.startDate).format('DD/MM/YYYY hh:mm A')}
                endDate={moment(props?.eventDetails?.endDate).format('DD/MM/YYYY hh:mm A')}
                isLocation={false}
              />
            )}
          {props?.eventDetails?.location !== 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_VENUE_LOCATION}
              isLocation
              img={images.location}
              endDate={moment(props?.eventDetails?.venueEndDate).format('DD/MM/YYYY hh:mm A')}
              startDate={moment(props?.eventDetails?.venueStartDate).format('DD/MM/YYYY hh:mm A')}
              address={venueAddress}
              secondaryTitle={OKTION_VARIABLE?.OKTION_LEFTFILTER_VENUE}
            />
          )}
          <p className="ms-5 ps-2 fw-500">+ &nbsp; {OKTION_VARIABLE?.OKTION_ONLINE_EVENT}</p>
        </Col>{' '}
      </Row>
      {isToggle && (
        <LogoutModal
          isToggle={isToggle}
          toggle={() => setToggle(!isToggle)}
          handleClick={handleLoginRedirect}
          isGuestCheckout
          singleItem={cartItem}
        />
      )}
      {isToggleValue && (
        <SendTicketModal
          isToggle={isToggleValue}
          toggle={() => setToggleValue(!isToggleValue)}
          ticketData={ticketData}
        />
      )}
      {isToggleModal && (
        <TicketDataModal
          isToggle={isToggleModal}
          toggle={() => setToggleModalValue(!isToggleModal)}
          item={ticketItemData}
          eventDetails={props?.eventDetails}
          ticket
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(Tickets);
