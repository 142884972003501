import Axios from 'axios';
import { errorHandler, fetchFromStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import headerResponseEventEmitter from 'library/utilities/headerResponse';
import Config from '../../utility/Config';

// Creating an Axios instance with default settings
const axiosInstance = Axios.create({
  baseURL: Config.BaseURL,
  headers: {
    'Content-Type': 'application/json',
    'platform-type': 'web',
    'oktion-connect-version': Config?.version,
    'api-connect-version': Config?.apiVersion,
    // 'ngrok-skip-browser-warning': 'true',
  },
});

// Adding a request interceptor
axiosInstance.interceptors.request.use(config => {
  // Fetching token from storage
  const token = fetchFromStorage(identifiers.token);
  const clonedConfig = config;

  // If a token is found, set the Authorization header
  if (token) {
    clonedConfig.headers = {
      ...clonedConfig.headers,
      Authorization: `Bearer ${token.token}`,
    };
  }

  return clonedConfig;
});

// Adding a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Update headers from the response
    updateHeaderResponse(response);
    return response; // Returning the response if successful
  },
  error => {
    updateHeaderResponse(error.response);
    errorHandler(error);
    return Promise.reject(error); // Rejecting the promise with the error
  },
  config => {
    return config; // Returning config (not needed, could be omitted)
  },
);

// Function to update the header response
const updateHeaderResponse = response => {
  const version = response?.headers?.['oktion-connect-version'];
  const maintenance = response?.headers?.['oktion-maintenance-mode'];
  const maintenanceType = response?.headers?.['oktion-maintenance-mode-type'];

  // Emitting events for header updates
  headerResponseEventEmitter.emit('headerResponseUpdated', version);
  if (maintenance) {
    const maintenanceData = {
      maintenance: maintenance,
      maintenanceType: maintenanceType,
    };
    headerResponseEventEmitter.emit('maintenanceMode', maintenanceData);
  }

  // Emitting crash event
  headerResponseEventEmitter.emit('oktionCrash', response);
};

export default axiosInstance;
