import React from 'react';
import { useDropzone } from 'react-dropzone';

import './fileUploaderStyles.scss';

const FileUpload = ({ accept, label, multiple, onDrop, text, disabled }) => {
  const handleFileDrop = acceptedFiles => {
    onDrop(
      acceptedFiles?.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept?.includes('image')
      ? {
          'image/jpeg': [],
          'image/png': [],
        }
      : null,
    multiple: multiple,
    onDrop: handleFileDrop,
    disabled: disabled,
  });

  return (
    <>
      <div className="file-uploader">
        {label && <p className="mb-1 inputField-label">{label}</p>}
        <div
          {...getRootProps({
            className: `dropzone d-flex justify-content-center align-items-center mx-auto ${
              disabled ? 'cursor-default' : ''
            }`,
          })}>
          <input {...getInputProps()} />
          <p>{text}</p>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
