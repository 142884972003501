import React, { useState, useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { addToCart } from '../../../../library/common/actions/SubscriptionAction';
import { connect } from 'react-redux';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from '../../../../library/common/components/PageLoader';
import { SocketContext } from 'main/context/socket';
import { setApiMessage } from 'library/common/constants/function';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';

import { CurrencyFormat } from 'utility';

const RaffleAddToCard = ({
  ticketName,
  price,
  noTicket,
  item,
  eventDetails,
  handleBtnClick,
  eventDetailsData,
  whitelabel,
  setClickItemData,
  islogin,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);

  var eventId = params?.id;
  const userDetail = fetchFromStorage(identifiers?.userDetail);

  // Create the object representing the current ticket in the cart
  var ticketCartData = {
    type: 'ticket',
    quantity: count,
    referenceId: null,
    subscriptionName: null,
    subscriptionPrice: null,
    name: item?.ticketBundleName,
    amount: item?.bundlePrize,
    purchaseReferenceId: item?.id,
    isRaffleTicket: true,
    donationAmount: null,
    eventId: eventDetailsData?.id,
    currency: eventDetailsData?.currency?.abbreviation,
    symbol: eventDetailsData?.currency?.symbol,
    event: eventDetailsData,
    eventName: eventDetailsData?.eventName,
    digiCommission: 0,
    ticketPrice: item?.bundlePrize,
    cardCharedAmount: 0,
    total: item?.bundlePrize * count,
  };

  // Handle ticket addition to cart for logged-in users
  const handleAddToCart = async () => {
    if (islogin && userDetail?.userType === 'normal') {
      setLoading(true);
      const senData = [
        {
          type: 'ticket',
          quantity: count,
          eventId: eventDetails?.eventId,
          referenceId: null,
          purchaseReferenceId: item?.id,
          isRaffleTicket: true,
        },
      ];
      try {
        const { status } = await axiosInstance.post(URLS.addToCart, senData);
        if (status === 201 || status === 200) {
          setLoading(false);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventId,
            },
          });
        }
      } catch (err) {
        setLoading(false);
        setApiMessage('error', err?.response?.data?.msg);
      }
    } else {
      handleGuestCheckout();
    }
  };

  // Handle guest user or invalid feature scenarios
  const handleGuestCheckout = async () => {
    var guestCheckoutArray = fetchFromStorage(identifiers?.guestcheckoutdata);
    const senData = {
      type: 'ticket',
      quantity: count,
      eventId: eventDetails?.eventId,
      referenceId: null,
      purchaseReferenceId: item?.id,
      isRaffleTicket: true,
    };

    let finalOldCart = guestCheckoutArray?.map(item => {
      return {
        type: item?.type,
        quantity: item?.quantity,
        eventId: item?.eventId,
        referenceId: item?.referenceId,
        purchaseReferenceId: item?.purchaseReferenceId,
        isRaffleTicket: item?.isRaffleTicket,
        donationAmount: item?.donationAmount,
      };
    });

    let filterguestCheckoutArray = {
      cartArray: [senData],
      oldCart: Array.isArray(finalOldCart) ? finalOldCart : [],
    };
    setLoading(true);
    try {
      const { status } = await axiosInstance.post(URLS.isValidFeture, filterguestCheckoutArray);
      if (status === 201 || status === 200) {
        setClickItemData(ticketCartData);
        handleBtnClick(ticketCartData);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err?.response?.data?.msg);
    }
  };

  // Handle incrementing the ticket count
  const handleAddTicketCount = () => {
    setCount(count + 1);
  };

  // Handle decrementing the ticket count
  const handleMinusTicketCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  // Handle input change for the ticket count input field
  const handleInputChange = event => {
    let inputValue = event.target.value;
    inputValue = inputValue === '' ? 1 : inputValue.replace(/^0/, '1');
    setCount(Number(inputValue));
  };

  // Select the input field text when focused
  const handleInputFocus = event => {
    event.target.select();
  };

  return (
    <>
      {loading && <PageLoader />}
      <div
        className="wonItemPrizeCard addToCart cursor-pointer"
        style={{
          pointerEvents: !eventDetails?.isActive ? 'none' : '',
          opacity: !eventDetails?.isActive ? '0.4' : '1',
        }}>
        <Card>
          <CardBody className="text-center">
            <p className="mb-4 mt-3 fw-500">{ticketName}</p>
            <div className="mb-2 fw-medium">
              <CurrencyFormat
                value={count * price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${eventDetailsData?.currency != null ? eventDetailsData?.currency?.abbreviation : ''} ${
                  eventDetailsData?.currency != null ? eventDetailsData?.currency?.symbol : ''
                }`}
                thousandSpacing="2s"
                decimalScale="2"
                fixedDecimalScale={true}
                className="text-currency"
              />
            </div>
            <h5 className="fw-normal">
              {noTicket} {noTicket === 1 ? OKTION_VARIABLE?.OKTION_TICKET : OKTION_VARIABLE?.OKTION_TICKET_TITLE}
            </h5>

            <div className="d-flex justify-content-center mt-4">
              <div
                className="addButtons"
                onClick={handleMinusTicketCount}
                style={{
                  backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
                  pointerEvents: whitelabel === true && 'none',
                  opacity: whitelabel === true && '0.5',
                }}>
                -
              </div>
              <input
                value={count}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                className="addButtonInput h-45"
                type="number"
                placeholder=""
              />
              <div
                className="addButtons"
                onClick={handleAddTicketCount}
                style={{
                  backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
                  pointerEvents: whitelabel === true && 'none',
                  opacity: whitelabel === true && '0.5',
                }}>
                +
              </div>
            </div>

            <Button
              className="main-button h-60 w-100 mt-3"
              onClick={() => {
                handleAddToCart();
              }}
              disabled={
                whitelabel === true || eventDetails?.isDraw === '1' || item?.totalCartItems > 0
                  ? true
                  : false || count === 0
              }
              style={{
                backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { addToCart })(RaffleAddToCard);
