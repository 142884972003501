import React, { useContext } from 'react';
import { Card, CardBody, CardImg, CardSubtitle, CardTitle } from 'reactstrap';
import Barcode from 'react-barcode';
import moment from 'moment';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from '../../../../../library/common/constants/IdentifierConstants';
import { fetchFromStorage } from '../../../../../utility';
import { SocketContext } from 'main/context/socket';
import '../../../../Events/MyEvents/frames/Features/frames/Auction/styles.scss';
import '../../../../../library/common/components/EventCard/styles.scss';

const TicketsCard = ({
  isBarcode,
  isTicket,
  ticketData,
  showImage,
  handleItemClick,
  imageUrl,
  printImage,
  raffleDrawDate,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const eventDetails = fetchFromStorage(identifiers.eventDetails);

  // Generate strings for barcode
  const barcodeForRaffleTicket = JSON.stringify({
    eventName: eventDetails?.eventName,
    eventId: eventDetails?.id,
    eventDate: moment(eventDetails?.startDate).format('DD/MM/YYYY hh:mm A'),
    raffleTicketNumber: ticketData?.ticketNumber,
    raffleTicketId: ticketData?.id,
    raffleDrawDate: moment(raffleDrawDate, moment.ISO_8601).format('DD/MM/YYYY hh:mm A'),
  });

  return (
    <div className="wonItemPrizeCard cursor-pointer" style={{ pageBreakAfter: 'always' }}>
      <Card onClick={() => handleItemClick(ticketData)}>
        {!showImage ? (
          ''
        ) : (
          <CardImg
            top
            width="100%"
            src={identifiers.imageUrlConstant + imageUrl}
            alt={OKTION_VARIABLE?.OKTION_CARD_IMAGE_CAP}
            className={printImage ? 'printImage' : ''}
            onError={e => ((e.target.src = images?.logo), (e.target.className = 'error-bg-img'))}
          />
        )}
        <CardBody>
          {!isTicket && (
            <>
              <CardTitle className="mb-2 mt-2 fw-500">{ticketData?.ticketNo}</CardTitle>
              <CardSubtitle className="mb-3">
                {OKTION_VARIABLE?.OKTION_RAFFLETICKET_RAFFLE_DRAW} : {ticketData?.raffleDraw}
              </CardSubtitle>
            </>
          )}
          {isTicket && <CardTitle className="m-0 fw-500">{ticketData?.ticketNumber}</CardTitle>}

          <div className="barcode-svg">
            {!isBarcode && (
              <Barcode value={barcodeForRaffleTicket} width={2} height={280} format="CODE128" displayValue={false} />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TicketsCard;
