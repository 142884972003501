import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import { getUserCards } from 'library/common/actions/UserCardsAction';
import { connect } from 'react-redux';
import PageLoader from 'library/common/components/PageLoader';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { setApiMessage } from 'library/common/constants/function';
import CustomPagination from 'library/common/components/Pagination/CustomPagination';
import { SocketContext } from 'main/context/socket';
import InvoicesTable from './InvoicesTable';
import { useParams } from 'react-router-dom';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import './styles.scss';

const Invoices = ({ isOrg }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [sortKey, setSortKey] = useState('id');
  const [sortType, setSortType] = useState(false);
  const limit = 10;
  const org_id = fetchFromStorage(identifiers.currentOrganization);

  const handlePaginationButtonClick = async navDirection => {
    if (navDirection === 'prev') {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        getInvoiceList(currentPage - 1, limit, sortKey, sortType);
      }
    } else {
      if (currentPage < Math.ceil(totalRecord / limit)) {
        setCurrentPage(currentPage + 1);
        getInvoiceList(currentPage + 1, limit, sortKey, sortType);
      }
    }
  };
  const handlePaginationClick = async (event, page) => {
    if (currentPage !== page) {
      setCurrentPage(Number(page));
      getInvoiceList(Number(page), limit, sortKey, sortType);
    }
  };

  const getInvoiceList = async (page, size, key, type) => {
    setLoading(true);
    let sortInvoice = `&sortKey=${key}&sortType=${type ? 'asc' : 'desc'}&organisationId=${isOrg ? org_id : ''}`;
    try {
      const { status, data } = await axiosInstance.get(URLS.getInvoice(page, size) + sortInvoice);
      if (status === 200) {
        setLoading(false);
        setInvoiceList(data?.data);
        setTotalRecord(data?.count);
      }
    } catch (err) {
      setLoading(false);
      if (err?.response) {
        setApiMessage('error', err?.response?.data?.msg);
      }
    }
  };

  useEffect(() => {
    getInvoiceList(currentPage, limit, sortKey, sortType);
  }, []);

  const handleSortInvoice = (key, type) => {
    setSortKey(key);
    setSortType(type);
    getInvoiceList(currentPage, limit, key, type);
  };

  return (
    <div className="card-details ps-3 invoices">
      {loading && <PageLoader />}
      <div className="align-items-center justify-content-lg-between mt-4 page-title-block">
        <div>
          <h1 className="text-center page-title text-lg-start">{OKTION_VARIABLE?.OKTION_INVOICES}</h1>
        </div>
      </div>
      {invoiceList && invoiceList.length > 0 ? (
        <div className="mb-3 mt-3 mt-lg-5 g-0">
          <div>
            <InvoicesTable
              invoiceList={invoiceList}
              loading={loading}
              setLoading={setLoading}
              sortType={sortType}
              handleSortInvoice={handleSortInvoice}
            />
          </div>
        </div>
      ) : (
        !loading && <p className="text-center">No data found</p>
      )}
      <Row className="mb-5">
        <Col md="12">
          <CustomPagination
            paginationArray={invoiceList}
            currentPage={currentPage}
            list={limit}
            totalRecord={totalRecord}
            handlePrevNextBtn={handlePaginationButtonClick}
            handleChangePage={handlePaginationClick}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ userCardsReducer }) => {
  return {
    userCards: userCardsReducer.userCards,
  };
};

export default connect(mapStateToProps, {
  getUserCards,
  // deleteUserCards,
  // UpdateDefaultCards,
})(Invoices);
