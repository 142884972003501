import React from 'react';
import Pagination from '@mui/material/Pagination';
import { images } from 'library/common/constants/ImageConstants';
import { saveToStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import './pagination.scss';

const CustomPagination = ({ paginationArray, currentPage, list, totalRecord, handlePrevNextBtn, handleChangePage }) => {
  saveToStorage(identifiers.pagination, currentPage);
  return (
    <>
      {paginationArray && paginationArray.length > 0 ? (
        <div className="pagination-main">
          <div className="tablePagination">
            <p
              className={totalRecord && currentPage !== 1 ? 'btn-navigation me-3' : 'btn-navigation-disabled me-3'}
              disabled={totalRecord && currentPage !== 1 ? false : true}
              onClick={() => {
                totalRecord && currentPage !== 1 && handlePrevNextBtn('prev');
              }}>
              <img src={images.prevIcon} alt="" />
            </p>
            <Pagination
              hideNextButton
              hidePrevButton
              disabled={totalRecord && totalRecord / list > 1 ? false : true}
              page={currentPage}
              onChange={handleChangePage}
              count={totalRecord && Math.ceil(totalRecord / list)}
            />
            <p
              className={
                currentPage < Math.ceil(totalRecord / list) ? 'btn-navigation ms-3' : 'btn-navigation-disabled ms-3'
              }
              disabled={totalRecord && currentPage < Math.ceil(totalRecord / list) ? false : true}
              onClick={() => {
                totalRecord && currentPage < Math.ceil(totalRecord / list) && handlePrevNextBtn('next');
              }}>
              <img src={images.nextIcon} alt="" />
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CustomPagination;
