import PopOver from 'library/common/components/PopOver/PopOver';
import { SocketContext } from 'main/context/socket';
import React, { useContext } from 'react';
import { Media } from 'reactstrap';
import '../styles.scss';

const EventTimeDetails = ({ img, address, startDate, endDate, isLocation, title, secondaryTitle }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <Media className="mb-4">
      <div className="res-clloca-icon">
        <img src={img} alt="" />
      </div>
      <Media body>
        {!isLocation ? (
          <Media heading>
            <p className="fw-500 pb-2">{title}</p>
            <p className="fs-16 fw-normal">
              {startDate} {endDate && '-'} {endDate && <br />}
              {endDate}
            </p>
          </Media>
        ) : (
          <Media heading>
            <div className="fw-500 pb-2 d-flex fs-18 align-items-center">
              {title}{' '}
              {title === OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN && (
                <PopOver
                  title={OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}
                  description={OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN_SUBTEXT}
                  target="visibility"
                />
              )}
            </div>
            <p className="fs-16 fw-normal pb-1">
              {Array.isArray(address) ? address?.map((line, index) => <span key={index}>{line}</span>) : address}
            </p>
            {secondaryTitle && (
              <>
                <p className="fs-16 fw-500 pt-3">{secondaryTitle}</p>
                <p className="fs-16 fw-normal">
                  {startDate} {endDate && '-'} {endDate && <br />}
                  {endDate}
                </p>
              </>
            )}
          </Media>
        )}
      </Media>
    </Media>
  );
};

export default EventTimeDetails;
