export const URLS = {
  login: '/public/user/login',
  registration: '/public/user/register',
  guestRegister: '/public/user/guestRegister',
  verifyEmail: '/public/user/email/verify',
  forgotPassword: '/public/user/password/forgot',
  verifyResendEmail: '/public/user/email/verify/resend',
  verifyForgotResetEmail: '/public/user/password/forgot/reset',
  createOrganization: '/private/organisation',
  updateOrganization: id => `/private/organisation/${id}`,
  updateEvent: id => `/private/event/${id}`,
  getOrganization: '/private/organisation',
  imageUpload: '/private/media/upload',
  multipleImageUpload: '/private/media/MultipleImage',
  organizationChangeRole: '/private/orgUser/changeRole',
  organizationByRole: (orgId, role) => `/private/orgUser/byRole?orgId=${orgId}&role=${role}`,
  getOrganizationManageEvent: id => `/private/organisation/${id}`,
  getCurrency: lang => `/private/currency`,
  getTimeZone: lang => `/private/timezone`,
  getEvent: `/private/event`,
  validateUrl: id => `/private/event/${id}/url/validate`,
  getByEventIdData: id => `/private/event/${id}`,
  getUserData: filter => `/private/user/search?filter=${filter}`,
  getUserDataLoggedin: filter => `/private/user`,
  removeOrgUser: orgUserId => `/private/orgUser/remove/${orgUserId}`,
  transferClick: (id, userId) => `/private/organisation/transfer/${id}?userId=${userId}`,
  orgBankAccounts: id => `/private/bankAccounts/${id}`,
  removeOrgBankAccount: id => `/private/bankAccounts/remove/${id}`,
  getNotification: (offset, limit) => `/private/notifications?offset=${offset}&limit=${limit}`,
  updateNotification: id => `/private/notifications/${id}`,
  getUserDeatails: `/private/user`,
  setUserDetails: `/private/user/update`,
  stripeCreateCard: customerId => `https://api.stripe.com/v1/customers/${customerId}/sources`,
  acceptInvite: `/private/user/accept`,
  userCards: checkout => `/private/cards?checkout=${checkout}`,
  deleteUserCards: id => `/private/cards/${id}`,
  changePassword: '/private/user/changePassword',
  addToCart: '/private/cart',
  getCard: cardId => `/private/cart?cardId=${cardId}`,
  getCardFees: '/public/payments/chargeInfo',
  deleteFromCart: id => `/private/cart/${id}`,
  addFeatureList: eventId => `/private/payments/feature?eventId=${eventId}`,
  getSubscriptionPlan: '/private/subscriptionPlans?type=subscription',
  getWhiteLableSubscriptionPlan: '/private/subscriptionPlans?type=domain',
  createNewTicket: '/private/eventFeature',
  updateFeatureList: '/private/payments/feature',
  getTicketByEventId: id => `/private/eventFeature/byEvent/${id}`,
  signInOrganisation: id => `/private/organisation/signIn/${id}`,
  buyProduct: '/private/payments',
  guestPayment: '/public/payments/guestPayment',
  getDashboardData: id => `/private/eventStats/dashboard?eventId=${id}`,
  verifyCouponCode: code => `/private/couponCode/verify?code=${code}`,
  deleteFeature: id => `/private/eventFeature/${id}`,
  deleteFeatureById: id => `/private/eventFeature?id=${id}`,
  getSponsors: id => `/private/sponsor?eventId=${id}`,
  submitSponsors: `/private/sponsor`,
  addAttendees: `/private/attendees/addAttendees`,
  getInvitedAttendees: (id, page, size) =>
    `/private/attendees/invitedAttendees?eventId=${id}&page=${page}&size=${size}`,
  getAttandance: eventId => `/private/attendees/getAttandance?eventId=${eventId}`,
  getAttandanceList: (eventId, page, size, allStatus) =>
    `/private/attendees/getAttandanceList?eventId=${eventId}&page=${page}&size=${size}&allStatus=${allStatus}`,
  scanAttendee: '/private/eventFeature/ticket/scan',
  createTicket: `/private/eventFeature/addTicket`,
  addEventTable: `/private/eventTable/upsert`,
  getEventTable: (id, eventId) => `/private/eventTable/${id}?eventId=${eventId}`,
  deleteEventTable: id => `/private/eventTable/${id}`,
  getAllEventTable: (eventId, filter) => `/private/eventTable?eventId=${eventId}&sort=${filter}`,
  getAllEventTableAfterUserAssign: (tableId, eventId) => `/private/eventTable/${tableId}?eventId=${eventId}`,
  withoutTable: eventId => `/private/attendees/invitedAttendees/withoutTable?eventId=${eventId}`,
  updatedSponsors: id => `/private/sponsor/${id}`,
  getDonationByEventId: (id, type, sortBy) =>
    typeof sortBy !== 'undefined'
      ? `/private/eventFeature/byEvent/${id}?type=${type}&sort=${sortBy}`
      : `/private/eventFeature/byEvent/${id}?type=${type}`,
  getAuctionLotList: (id, type, sortBy, offset, limit, tab) =>
    typeof sortBy !== 'undefined'
      ? `/private/eventFeature/byEvent/${id}?type=${type}&sort=${sortBy}&offset=${offset}&limit=${limit}&tab=${tab}`
      : `/private/eventFeature/byEvent/${id}?type=${type}&offset=${offset}&limit=${limit}&tab=${tab}`,
  getEventStatistics: (id, type) => `/private/eventStats?eventId=${id}&type=${type}`,
  createSupply: `/private/supplyItem`,
  getSupplyItemByStatus: status => `/private/supplyItem?itemStatus=${status}`,
  updateSupplyItem: id => `/private/supplyItem/${id}`,

  getAllSupplyItem: (itemStatus, order, page, size, character) =>
    `/private/supplyItem/all?itemStatus=${itemStatus}&order=${order}&page=${page}&size=${size}&findChar=${character}`,

  getAllSupplyItemWithCat: (filter, itemStatus, order, cat, page, size, character) =>
    `/private/supplyItem/all?filter=${filter}&itemStatus=${itemStatus}&order=${order}&category=${cat}&page=${page}&size=${size}&findChar=${character}`,
  relistSupplyItem: id => `/private/supplyItem/relist/${id}`,
  getPublicEventFilter: queryString => `/public/events/search${queryString}`,
  getPublicEvent: `/public/events`,
  changeTableAssignment: `/private/eventTableUser/upsert`,
  eventTableUserStatus: id => `/private/eventTableUser/${id}`,
  getPublicEventByid: (id, previewStatus) => `/public/events/${id}?previewStatus=${previewStatus}`,
  getPublicEventByToken: (id, token, previewStatus) =>
    `/public/events/${id}?token=${token}&previewStatus=${previewStatus}`,
  getFeatureByEventId: (id, previewStatus, token) =>
    `/public/events/${id}/features?previewStatus=${previewStatus}${token ? `&token=${token}` : ''}`,
  getCategory: `/private/category`,
  getCategoryPublic: eventId => `/public/category?eventId=${eventId}`,
  exportSheet: (eventId, cases) => `/private/export/${eventId}?cases=${cases}`,
  myEvents: `/private/myEvents`,
  addToCalender: eventId => `/private/addToCalender/${eventId}`,
  cancelSubscriptionPurchased: `/private/subscriptionPurchased/cancel`,
  downgradeSubscription: `/private/subscriptionPurchased/downgrade`,
  filterPublicEventFeature: (id, sortBy) => `/public/events/${id}/features/auction/all?sort=${sortBy}`,
  auctionAll: (id, queryString) => `/public/events/${id}/features/auction/all${queryString}`,
  followOrganization: id => `/private/organisation/follow/${id}`,
  unfollowOrganization: id => `/private/organisation/unfollow/${id}`,
  followOrganizationList: `/private/organisation/follow`,
  organizationDetail: id => `/private/orgDetail/${id}`,
  getCurrentOrgevent: `/private/event/byCurrentOrganisation`,
  cloneSupplyItem: `/private/eventFeature/supplyItem/clone`,
  eventDashboard: id => `/private/eventStats/${id}/dashboard`,
  getEventStatistics: (eventId, type) => `/private/eventStats?eventId=${eventId}&type=${type}`,
  createBid: itemId => `/private/bidding/${itemId}/bid`,
  buyItNow: itemId => `/private/bidding/${itemId}/buyItNow`,
  publicOrganizationList: id => `/public/organisation/${id}`,
  getTicketPurchase: (eventId, status) => `/private/eventFeature/ticket/${eventId}?raffleTicket=${status}`,
  eventWiseticket: (eventIds, orderIds) =>
    `/private/eventFeature/eventWiseticket/list?eventId=${eventIds}&raffleTicket=false&orderIds=${orderIds}`,
  purchaseTicketUpdate: `/private/eventFeature/ticket/update`,
  getCategoryByEventId: id => `/public/category?eventId=${id}`,
  getPopularEvent: (offset, limit, tab) => `/public/events/popular?offset=${offset}&limit=${limit}&tab=${tab}`,
  getContributionHistory: `/private/contribution`,
  getContributionHistoryById: eventId => `/private/contribution/${eventId}`,
  validateTicket: `/private/cart/validate/check`,
  getUserChatList: `/private/message`,
  getChatbyid: (id, queryString) => `/private/message/${id}${queryString}`,
  createChatMessage: '/private/message',
  getCountry: `/private/country`,
  validateEmail: `/public/user/email/validate`,
  getRaffleDrawList: id => `/private/raffleDraw/${id}`,
  raffleDraw: (eventId, price_id, isDrawAll) => `/private/raffleDraw/${eventId}?id=${price_id}&isDrawAll=${isDrawAll}`,
  countryDetails: 'https://ipapi.co/json/',
  winLosses: type => `/private/winLosses/?type=${type}`,
  getActionWallDetails: id => `/private/bidding/auctionWall?featureId=${id}`,
  getRaffleWallDetails: id => `/private/eventFeature/raffleWall/list?featureId=${id}`,
  eventFollow: id => `/private/event/follow/${id}`,
  eventUnFollow: id => `/private/event/unfollow/${id}`,
  eventfollowlist: id => `/private/myEvents?isFollow=${id}`,
  signOut: `/private/user/logout`,
  logoutByUserId: id => `/public/user/logoutByUserId?userId=${id}`,
  getFetureStatus: status => `/private/payments/feature?quickFeatureStatus=${status}`,
  getsocialLInks: id => `/public/organisation/organisationSocialLinksList/${id}`,
  addsocialLInks: id => `/private/organisation/createOrganisationSocialLinks/${id}`,
  gestCheckoutEmail: `/public/user/guestLogin`,
  sponsersCreate: `/private/sponsor/create`,
  deleteSponserById: id => `/private/sponsor/${id}`,
  deleteSubSponserById: id => `/private/sponsorCategory/${id}`,
  isValidFeture: `/public/payments/validate`,
  otpVerify: `/public/user/guest/verify`,
  GuestCart: `/private/cart/guest`,
  sendGuestOTP: '/public/user/guest/otp',
  getVerificationURL: `/private/user/verification`,
  multipleDocUpload: `/private/organisation/document-upload`,
  updateVerificationURL: `/private/user/verification`,
  createCardURL: 'private/organisation/create-card',
  orgCardListURL: '/private/organisation/cardlist',
  updateCardURL: '/private/organisation/update-card',
  verifyCardURL: '/private/organisation/verifiy-card',
  deleteCardURL: '/private/organisation/delete-card',
  getInvoice: (page, size) => `/private/invoice?offset=${page}&limit=${size}`,
  downlodaInvoice: id => `/private/invoice/download/${id}`,
  payoutURL: '/public/events/payout',
  FEATURE_RE_ORDER: '/private/eventFeature/re-order-items',
};
