import React, { useState, useEffect, useContext } from 'react';
import { Button, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';
import LogoutModal from '../../../Raffle/LogoutModal';

import { CurrencyFormat } from 'utility';

const BiddingAmountCount = ({
  handleSetMaxBid,
  handleBidNow,
  handleBuyItNow,
  handleAdd,
  handleMinus,
  count,
  currency,
  currencySymbol,
  singleItem,
  setQuickBid,
  auctionFeatureData,
  eventDetailsData,
  isLoggedIn,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [bidCount, setBidCount] = useState(count);
  const [biddingData, setBiddingData] = useState(singleItem);
  const [isLoginToggle, setLoginToggle] = useState(false);

  // Fetch auction data when component mounts or auction is updated
  useEffect(() => {
    // Subscribe to socket updates for bidding data
    EventEmitter.subscribe('updateBiddingData', auctionData => {
      var auction_data = auctionData?.find(item => item?.id === singleItem?.id);
      setBiddingData(auction_data);
    });
  }, []);

  // Update bid count when the count prop changes (handleAdd, handleMinus)
  useEffect(() => {
    setBidCount(count);
  }, [count]);

  // Function to toggle logout modal
  const toggleModal = () => {
    setLoginToggle(!isLoginToggle);
  };

  // Navigate to login if the user is not logged in
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'auction',
        eventId: params?.id,
      },
    });
  };

  return (
    <>
      <Col sm="12" className={setQuickBid === true ? 'mt-3 p-0' : 'bidding-count mt-3'}>
        <div className="d-flex justify-content-between sm-d-flex align-items-center">
          <div
            className={auctionFeatureData[0]?.isActive === false ? 'addButtons blur-section' : 'addButtons'}
            onClick={() => handleMinus()}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            -
          </div>
          <h5>
            <CurrencyFormat
              value={bidCount !== null ? bidCount : '0.00'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${currency} ${currencySymbol}`}
              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
              decimalScale="2"
              fixedDecimalScale={true}
              style={{ fontSize: '20px' }}
            />
          </h5>
          <div
            className={auctionFeatureData[0]?.isActive === false ? 'addButtons blur-section' : 'addButtons'}
            onClick={() => handleAdd()}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            +
          </div>
        </div>
        <Button
          onClick={() => handleBuyItNow()}
          className={
            singleItem?.isSold || auctionFeatureData[0]?.isActive === false
              ? 'w-100 btn-block mt-2'
              : 'w-100 btn-block mt-2 blue-button'
          }
          disabled={
            singleItem?.isSold ||
            auctionFeatureData[0]?.isActive === false ||
            singleItem?.currentBid >= singleItem?.buyItNow ||
            singleItem?.buyItNow === null ||
            singleItem?.markAsPaid ||
            singleItem?.buyItNow === 0
          }>
          {singleItem > singleItem?.buyItNow || singleItem?.buyItNow === null || singleItem?.buyItNow === 0 ? (
            OKTION_VARIABLE?.OKTION_BUYIT_NOW
          ) : (
            <CurrencyFormat
              value={singleItem?.buyItNow !== null ? singleItem?.buyItNow : '0.00'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${OKTION_VARIABLE?.OKTION_BUYIT_NOWFOR} ${currency} ${currencySymbol}`}
              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
              decimalScale="2"
              fixedDecimalScale={true}
            />
          )}
        </Button>
        <div className="d-flex justify-content-between sm-d-flex">
          <Button
            onClick={() => handleBidNow()}
            className={eventDetailsData?.primaryColour ? 'btn-block mt-2 me-3' : 'main-button btn-block mt-2 me-3'}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}
            disabled={singleItem?.isSold || auctionFeatureData[0]?.isActive === false || singleItem?.markAsPaid}>
            {OKTION_VARIABLE?.OKTION_BID_NOW}
          </Button>
          <Button
            onClick={() => handleSetMaxBid()}
            className={eventDetailsData?.primaryColour ? 'btn-block mt-2' : 'main-button btn-block mt-2'}
            disabled={singleItem?.isSold || auctionFeatureData[0]?.isActive === false || singleItem?.markAsPaid}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            {OKTION_VARIABLE?.OKTION_SETMAX_BID}
          </Button>
        </div>
        {setQuickBid === true ? (
          <div className="d-flex justify-content-around mt-2">
            <div>
              <p
                className="sm-fs-12"
                style={{
                  color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
                }}>
                {OKTION_VARIABLE?.OKTION_CURRENTBID}{' '}
              </p>
              <p className="d-flex justify-content-center">
                <CurrencyFormat
                  value={biddingData?.myCurrentBid !== null ? biddingData?.myCurrentBid : '0.00'}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={`${currency} ${currencySymbol}`}
                  thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                  decimalScale="2"
                  fixedDecimalScale={true}
                  style={{ fontSize: '18px' }}
                />
              </p>
            </div>
            <div>
              <p
                className="sm-fs-12"
                style={{
                  color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
                }}>
                {OKTION_VARIABLE?.OKTION_MAX_BID}
              </p>
              <p className="d-flex justify-content-center">
                <CurrencyFormat
                  value={biddingData?.myMaxBid !== null ? biddingData?.myMaxBid : '0.00'}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={`${currency} ${currencySymbol}`}
                  thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                  decimalScale="2"
                  fixedDecimalScale={true}
                  style={{ fontSize: '18px' }}
                />
              </p>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-around mt-2">
            <p
              className="sm-fs-12"
              style={{
                color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_CURRENTBID} :{' '}
              <CurrencyFormat
                value={biddingData?.myCurrentBid !== null ? biddingData?.myCurrentBid : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currency} ${currencySymbol}`}
                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
                style={{ fontSize: '18px' }}
              />
            </p>
            <p
              className="sm-fs-12"
              style={{
                color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_MAX_BID} :{' '}
              <CurrencyFormat
                value={biddingData?.myMaxBid !== null ? biddingData?.myMaxBid : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currency} ${currencySymbol}`}
                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
                style={{ fontSize: '18px' }}
              />
            </p>
          </div>
        )}
      </Col>
      {/* Card details required modal */}
      <LogoutModal isToggle={isLoginToggle} toggle={toggleModal} handleClick={handleLoginRedirect} />
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(BiddingAmountCount);
