import React, { useState, useContext, useEffect } from 'react';
import { Button, Col, Row, Modal, ModalBody } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import QRCode from 'react-qr-code';
import parse from 'html-react-parser';
import anchorme from 'anchorme';

import EventTimeDetails from '../EventTimeDetails';
import { images } from '../../../../library/common/constants/ImageConstants';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import axiosInstance from '../../../../main/axios';
import Config from 'utility/Config';
import { setApiMessage } from '../../../../library/common/constants/function';
import { ReactComponent as Closed } from '../../../../resources/images/close.svg';
import LogoutModal from '../Raffle/LogoutModal';
import PageLoader from '../../../../library/common/components/PageLoader';
import { SocketContext } from 'main/context/socket';

const About = props => {
  const params = useParams();
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [isLoginToggle, setIsLoginToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  // Effect for window resize handling
  useEffect(() => {
    const updateDimensions = () => setWidth(window.innerWidth);
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // Handles the add to calendar if loggeding
  const handleAddToCalendarBtn = () => {
    if (!props?.isLoggedIn) {
      setIsLoginToggle(true);
    }
    if (props?.isLoggedIn) {
      handleAddToCalendar();
    }
  };

  // Makes API call to add event to user's calendar
  const handleAddToCalendar = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.addToCalender(props?.eventDetails?.id));
      if (status === 200 || status === 201) {
        setApiMessage('success', OKTION_VARIABLE?.OKTION_EVENT_ADDED_IN_CALENDER);
        setTimeout(() => {
          navigate(`/my-event-page`);
        }, 4000);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 403 && err?.response?.data?.msg === OKTION_VARIABLE?.OKTION_ALREADY_ADDED) {
        setApiMessage('error', err?.response?.data?.msg);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  // Handles navigation to login page with redirect information
  const handleButtonClick = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        eventId: params?.id,
      },
    });
  };

  const venueAddress = [
    props?.eventDetails?.venue?.addressLine1,
    props?.eventDetails?.venue?.addressLine2 + ',',
    <br />,
    `${props?.eventDetails?.venue?.city} ${props?.eventDetails?.venue?.state} ${props?.eventDetails?.venue?.country} ${props?.eventDetails?.venue?.postCode}`,
  ];
  return (
    <>
      {loading && <PageLoader />}
      <Row className="res-org-about">
        <Col md="8" lg="9">
          <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_ABOUT_EVENT}</h4>
          <div>
            {props?.eventDetails?.eventAbout != null ? (
              <div className="fs-16 font-Segoe_UI">
                {parse(
                  anchorme({
                    input: (props?.eventDetails?.eventAbout).toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </Col>
        <Col md="4" lg="3" className="pt-4 mt-3 mb-3">
          <div className="event-qr-wrap pt-lg-4 pb-3 border-bottom mb-3 d-flex hide-xs">
            <QRCode id="QRCode" size={77} value={`${Config.socketUrl}event-details/${params?.id}`} />
            <div className="qr-content ps-3 d-flex flex-column justify-content-center">
              <h4 className="pb-2">{OKTION_VARIABLE?.OKTION_QR}</h4>
              <span
                className="cursor-pointer"
                onClick={() => {
                  setQrModal(!qrModal);
                }}>
                {OKTION_VARIABLE?.OKTION_LARGE_VIEW}
              </span>
            </div>
          </div>
          {props?.timeZone !== undefined &&
            (props?.eventDetails?.location === 1 || props?.eventDetails?.location === 3) && (
              <EventTimeDetails
                img={images.watch}
                title={OKTION_VARIABLE?.OKTION_LEFTFILTER_EVENTDATE}
                startDate={
                  props?.eventDetails?.startDate
                    ? moment(props?.eventDetails?.startDate).format('DD/MM/YYYY hh:mm A')
                    : ''
                }
                endDate={
                  props?.eventDetails?.endDate ? moment(props?.eventDetails?.endDate).format('DD/MM/YYYY hh:mm A') : ''
                }
                isLocation={false}
              />
            )}
          {props?.eventDetails?.location !== 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_VENUE_LOCATION}
              isLocation
              img={images.location}
              endDate={
                props?.eventDetails?.venueEndDate
                  ? moment(props?.eventDetails?.venueEndDate).format('DD/MM/YYYY hh:mm A')
                  : ''
              }
              startDate={
                props?.eventDetails?.venueStartDate
                  ? moment(props?.eventDetails?.venueStartDate).format('DD/MM/YYYY hh:mm A')
                  : ''
              }
              address={props?.eventDetails?.venue ? venueAddress : []}
              secondaryTitle={OKTION_VARIABLE?.OKTION_LEFTFILTER_VENUE}
            />
          )}

          {props?.eventDetails?.location === 3 && (
            <p className="ms-5 ps-2 fw-500">+ &nbsp; {OKTION_VARIABLE?.OKTION_ONLINE_EVENT}</p>
          )}
          <Button
            className="main-button btn-block mb-3 mt-4 fs-20 fw-500 h-60"
            disabled={props.whitelabel !== true ? false : true}
            style={{
              backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
              borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
              color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
            }}
            onClick={handleAddToCalendarBtn}>
            {OKTION_VARIABLE?.OKTION_ADD_TO_CALENDER}
          </Button>
        </Col>
        <Col sm="12">
          <hr />
        </Col>

        {/* Login reqired modal */}
        <LogoutModal
          isToggle={isLoginToggle}
          toggle={() => setIsLoginToggle(prev => !prev)}
          handleClick={handleButtonClick}
        />

        <Modal
          size="xl"
          className="modal-dialog qr-modal"
          isOpen={qrModal}
          style={{ maxWidth: '406px', margin: '0 auto' }}
          centered>
          <Closed
            className="model-close"
            onClick={() => {
              setQrModal(!qrModal);
            }}
          />
          <div className="text-center pb-3 mb-1 pt-3 mt-1">
            <h4> {OKTION_VARIABLE?.OKTION_SCANE_QR}</h4>
          </div>
          <ModalBody className="text-center">
            <QRCode
              id="QRCode"
              size={width > 599 ? 366 : width > 375 ? 314 : 220}
              value={`${Config.socketUrl}event-details/${params?.id}`}
            />
          </ModalBody>
        </Modal>
      </Row>
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(About);
