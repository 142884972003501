import React from 'react';
import { Button, Modal, ModalBody, Collapse, Input } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import { images } from '../../../../library/common/constants/ImageConstants';
import PageLoader from '../../../../library/common/components/PageLoader';
import Notice from '../../../../library/common/components/Notice';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { SocketContext } from 'main/context/socket';
import { setApiMessage } from '../../../../library/common/constants/function';

class SendTicketModal extends React.Component {
  state = {
    isValidateCall: false,
    donationTicketBundle: [
      {
        amount: '',
        name: '',
        toggleBundle: true,
        status: '',
        mobile: '',
        email: '',
      },
    ],
    formData: {},
    labelVisible: false,
    loading: false,
    ErrorMessage: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ticketData !== this.props.ticketData) {
      this.setState({ donationTicketBundle: this.props.ticketData });

      // Maps the ticket data and updates the formData state
      var TICKET_DEFAULT_DATA = this.props.ticketData;
      TICKET_DEFAULT_DATA.map((item, i) => {
        let nameId = `name-${i}`,
          mobileId = `mobile-${i}`,
          emailId = `email-${i}`;

        // Creating form data object dynamically for each ticket
        const TEMPOBJ = {
          [nameId]: item?.name,
          [mobileId]: item?.phoneNumber,
          [emailId]: item?.email,
        };
        // Updating formData state with new ticket information
        this.setState(state => ({
          formData: {
            ...state.formData,
            ...TEMPOBJ,
          },
        }));
        return item;
      });
    }
  }

  // Validation function for form inputs
  formDataValidate = () => {
    let flag = true;
    let ErrorMessages = [];
    const { formData } = this.state;
    var donationTicketBundleLength = this.state.donationTicketBundle.length;
    let name = '';
    let email = '';

    // Loop through each ticket bundle and validate its fields
    for (var i = 0; i < donationTicketBundleLength; i++) {
      let { OKTION_VARIABLE } = this.props;
      amount = `amount-${i}`;
      name = `name-${i}`;
      mobile = `mobile-${i}`;
      email = `email-${i}`;

      // Check if name is filled
      if (typeof formData[name] === 'undefined' || formData[name] === '') {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [name]: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
      }

      // Check if email is filled and valid
      if (typeof formData[email] === 'undefined' || formData[email] === '') {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [email]: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
      } else if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formData[email])) {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [email]: OKTION_VARIABLE?.OKTION_EMAIL_VALID };
      }
      // Update error messages in state
      this.setState({ ErrorMessage: ErrorMessages });
    }
    return flag;
  };

  // Toggles the visibility of the ticket bundle's content
  handleToggleBundle = idx => {
    let donationTicketBundle = [...this.state.donationTicketBundle];
    for (let i = 0; i < donationTicketBundle.length; i++) {
      if (idx === i) {
        donationTicketBundle[i].toggleBundle = !donationTicketBundle[i].toggleBundle;
      }
    }
    this.setState({ donationTicketBundle });
  };

  // Handles input changes for the form and updates the formData state
  handleInputChange = event => {
    let { name, value } = event.target;
    this.setState(state => ({
      formData: {
        ...state.formData,
        [name]: value,
      },
      labelVisible: true,
    }));
  };

  // Handles changes specifically for ticket bundle inputs and triggers validation if needed
  handleChange = e => {
    const { isValidateCall } = this.state;
    if (['name form-control', 'amount form-control', 'email form-control'].includes(e.target.className)) {
      let donationTicketBundle = [...this.state.donationTicketBundle];
      donationTicketBundle[e.target.dataset.id][e.target.className.split(' ')[0]] = e.target.value;
      this.setState({ donationTicketBundle }, () => {
        isValidateCall && this.formDataValidate();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  // Submits the form, sends the data to the API, and handles success/error responses
  handleSubmit = async () => {
    var { OKTION_VARIABLE } = this.props;
    if (this.formDataValidate()) {
      this.setState({ loading: true });
      var sendData = [];

      // Prepares the data to be sent to the API based on the ticket bundle info
      sendData = this.state.donationTicketBundle.map((item, index) => {
        name = `name-${index}`;
        mobile = `mobile-${index}`;
        email = `email-${index}`;
        return {
          ticketId: item?.id,
          email: item?.email,
          mobileNumber: parseInt(item?.amount),
          name: item?.name,
        };
      });
      try {
        const { status } = await axiosInstance.put(URLS.purchaseTicketUpdate, sendData);
        this.setState({ loading: false });
        if (status === 200 || status === 304) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_TICKET_SEND);

          setTimeout(() => {
            this.props.toggle();
          }, 4000);
        }
      } catch (err) {
        setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);

        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { OKTION_VARIABLE } = this.props;
    const { isToggle, toggle } = this.props;
    const { loading, donationTicketBundle, ErrorMessage, formData } = this.state;
    return (
      <>
        <div>
          <Modal size="xl" className="modal-dialog add-price-modal ticket-purchase-modal" isOpen={isToggle}>
            <div className="text-end">
              <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
            </div>
            <ModalBody>
              <div className="ticketBundleTab">
                {loading && <PageLoader />}

                {donationTicketBundle.length > 0 ? (
                  <>
                    <h2 className="pb-3">{OKTION_VARIABLE?.OKTION_TICKET_PURCHASE}</h2>
                    <Notice
                      title={OKTION_VARIABLE?.OKTION_TICKET_SEND_TO_GUEST}
                      image={images.bulb}
                      message={OKTION_VARIABLE?.OKTION_TICKET_SEND_TO_GUEST_DETAILS}
                    />{' '}
                    {donationTicketBundle.map((val, idx) => {
                      let nameId = `name-${idx}`,
                        mobileId = `mobile-${idx}`,
                        emailId = `email-${idx}`;

                      return (
                        <div className="mt-3 discount-accordion" key={idx}>
                          <div className="row">
                            <div className="col-sm-10 cursor-pointer" onClick={() => this.handleToggleBundle(idx)}>
                              <h5>{`${val?.ticketNumber} ( ${val?.ticketName} )`}</h5>
                            </div>
                            <div className="col-sm-2 text-end">
                              <span
                                className="accordian_arrow cursor-pointer"
                                onClick={() => this.handleToggleBundle(idx)}>
                                <img className={`${!val.toggleBundle && 'down'}`} alt="" src={images.uppArraow} />
                              </span>
                            </div>
                          </div>

                          <Collapse isOpen={val.toggleBundle}>
                            <form onChange={this.handleChange}>
                              <FormGroup
                                className={
                                  ErrorMessage[nameId] !== '' &&
                                  ErrorMessage[nameId] !== undefined &&
                                  formData.hasOwnProperty(nameId)
                                    ? ' mt-2 focus_field fixed-fieldset'
                                    : ' mt-2 focus_field '
                                }>
                                <Input
                                  name={nameId}
                                  data-id={idx}
                                  id={nameId}
                                  value={val.name}
                                  className="name"
                                  type="text"
                                  placeholder={OKTION_VARIABLE?.OKTION_TICKET_FULLNAME_REQUIRED}
                                  onChange={e => this.handleInputChange(e)}
                                />
                                <label className="label-helper">{OKTION_VARIABLE?.OKTION_FULL_NAME}</label>
                                {ErrorMessage[nameId] && <span className="text-danger">{ErrorMessage[nameId]}</span>}
                              </FormGroup>

                              <FormGroup
                                className={
                                  ErrorMessage[mobileId] !== '' &&
                                  ErrorMessage[mobileId] !== undefined &&
                                  formData.hasOwnProperty(mobileId)
                                    ? ' mt-2 focus_field fixed-fieldset'
                                    : ' mt-2 focus_field '
                                }>
                                <Input
                                  name={mobileId}
                                  data-id={idx}
                                  id={mobileId}
                                  value={val.amount}
                                  className="amount ticketAmount"
                                  type="number"
                                  placeholder={OKTION_VARIABLE?.OKTION_TABLE_MOBILE_NUMBER}
                                  onChange={e => this.handleInputChange(e)}
                                  style={{ paddingLeft: '15px' }}
                                />
                                <label className="label-helper">{OKTION_VARIABLE?.OKTION_TABLE_MOBILE_NUMBER}</label>
                              </FormGroup>

                              <FormGroup
                                className={
                                  ErrorMessage[emailId] !== '' &&
                                  ErrorMessage[emailId] !== undefined &&
                                  formData.hasOwnProperty(emailId)
                                    ? ' mt-2 focus_field fixed-fieldset'
                                    : ' mt-2 focus_field '
                                }>
                                <Input
                                  name={emailId}
                                  data-id={idx}
                                  id={emailId}
                                  value={val.email}
                                  className="email"
                                  type="text"
                                  placeholder={OKTION_VARIABLE?.OKTION_EMAIL_REQUIRED}
                                  onChange={e => this.handleInputChange(e)}
                                  style={{ maxWidth: '100%' }}
                                />
                                <label className="label-helper">{OKTION_VARIABLE?.OKTION_EMAIL}</label>

                                {ErrorMessage[emailId] && <span className="text-danger">{ErrorMessage[emailId]}</span>}
                              </FormGroup>
                            </form>
                          </Collapse>
                        </div>
                      );
                    })}
                    <div className="mt-5 d-flex justify-content-center">
                      <Button className="app-button black-button mt-2 me-2 ticket-btn" onClick={toggle}>
                        {OKTION_VARIABLE?.OKTION_SKIPFOR_NOW}
                      </Button>
                      <Button
                        className="app-button main-button mt-2 me-2 ticket-btn"
                        onClick={e => {
                          this.handleSubmit();
                          this.setState({ isValidateCall: true });
                        }}>
                        {OKTION_VARIABLE?.OKTION_TICKET_SENDTICKET}
                      </Button>
                    </div>
                  </>
                ) : (
                  <h3 className="text-center mb-4">{OKTION_VARIABLE?.OKTION_NODATA_FOUND}</h3>
                )}
              </div>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

const SendTicketModalWithSocket = props => (
  <SocketContext.Consumer>
    {userSocket => <SendTicketModal {...props} userSocket={userSocket.socket} OKTION_VARIABLE={userSocket?.messages} />}
  </SocketContext.Consumer>
);

export default SendTicketModalWithSocket;
