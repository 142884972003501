import React, { useState, useContext } from 'react';
import CarouselSlider from '../../../UserProfile/Frame/WinLoss/Frame/WinLossModal/Slider';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import { Modal, ModalBody, Table } from 'reactstrap';
import { images } from '../../../../library/common/constants/ImageConstants';
import { SocketContext } from 'main/context/socket';

const RaffleSlider = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  let newWinnerArray = JSON.parse(props?.sliderData?.winnerTicket);

  const [isToggle, setToggle] = useState(false);

  const toggle = () => {
    setToggle(!isToggle);
  };
  return (
    <>
      <div className="mb-5 mt-4 border-bottom pb-4">
        <CarouselSlider images={props.sliderData.images} />
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="mt-3 mb-2 ">
              {props?.index + 1}
              {props?.index === 0 ? 'st' : props?.index === 1 ? 'nd' : props?.index === 2 ? 'rd' : 'th'}{' '}
              {OKTION_VARIABLE?.OKTION_PRIZE} : {props?.sliderData?.prizeName}
            </h5>
          </div>
          <div>
            {newWinnerArray && newWinnerArray.length === 1 ? (
              <h5 className="mb-2 mt-3 fw-normal">
                {props?.sliderData?.winnerTicket != null &&
                  OKTION_VARIABLE?.OKTION_WINNER_TICKET_NUMBER + newWinnerArray[0]}
              </h5>
            ) : (
              <h5 className="mb-2 mt-3 fw-normal cursor-pointer" onClick={toggle}>
                {props?.sliderData?.winnerTicket != null &&
                  newWinnerArray.length !== 0 &&
                  OKTION_VARIABLE?.OKTION_SEE_ALL_WINNERS}
              </h5>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="mb-2 fw-normal">
              {OKTION_VARIABLE?.OKTION_PROVIDED_BY} : {props?.sliderData?.itemProvidedByName}
            </h5>
          </div>
          <div>
            <h5 className="mb-2 fw-normal">
              {props?.sliderData?.numberOfPrizes + ' '} {OKTION_VARIABLE?.OKTION_TOBE_WON}
            </h5>
          </div>
        </div>
        <div className="fs-16">
          {props?.sliderData?.prizeAbout !== null ? (
            <div>
              {parse(
                anchorme({
                  input: (props?.sliderData?.prizeAbout).toString().replace(/\n/g, '<br/>'),
                  options: {
                    attributes: {
                      target: '_blank',
                      class: 'detected',
                    },
                  },
                }),
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div>
        <Modal size="lg" className="customModal add-price-modal draw_winner_modal" isOpen={isToggle}>
          <div className="text-end">
            <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
          </div>
          <div className="d-flex modalHeader">
            <div>
              <h2 className="mb-3">{OKTION_VARIABLE?.OKTION_PRIZE_WINNER}</h2>
              <p className="mb-1 fw-500">
                {props?.index + 1}
                {props?.index === 0 ? 'st' : props?.index === 1 ? 'nd' : props?.index === 2 ? 'rd' : 'th'}{' '}
                {OKTION_VARIABLE?.OKTION_PRIZE} : {props?.sliderData?.prizeName}
              </p>{' '}
              <p className="mb-1 fw-normal">
                {OKTION_VARIABLE?.OKTION_PROVIDED_BY} : {props?.sliderData?.itemProvidedByName}
              </p>{' '}
            </div>
          </div>
          <ModalBody className="pt-0">
            <div className="draw_table">
              <div className="mb-4">
                <>
                  <Table className="text-center">
                    <thead>
                      <tr>
                        <th>{OKTION_VARIABLE?.OKTION_NO}.</th>
                        <th>{OKTION_VARIABLE?.OKTION_WINNER_TICKET}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newWinnerArray &&
                        newWinnerArray
                          .filter(item => item !== null)
                          .map((winner, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{winner}</td>
                                </tr>
                              </>
                            );
                          })}
                    </tbody>
                  </Table>
                </>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default RaffleSlider;
