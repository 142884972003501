import React from 'react';
import { Button } from 'reactstrap';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import PageLoader from '../../../../library/common/components/PageLoader';
import { CurrencyFormat } from 'utility';

const TicketsList = props => {
  var ticketValue = props?.item?.eventFeatureOptions?.filter(item => item?.key === 'priceperticket');

  const handleInputFocus = event => {
    event.target.select();
  };

  return (
    <>
      <div className="border-bottom">
        <div className="res-ticketlist-display">
          {props?.loading && <PageLoader />}
          <div className="res-ticket-data">
            <h5 className="mb-2 rst-name">{props?.item?.featureName}</h5>
            <div className="fw-normal rst-price mb-2">
              <CurrencyFormat
                value={ticketValue[0]?.value || '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${props?.eventDetails?.currency != null ? props?.currency : ''} ${
                  props?.eventDetails?.currency != null ? props?.currencySymbol : ''
                }`}
                thousandSpacing="2s"
                decimalScale="2"
                fixedDecimalScale={true}
                className="rst-price"
              />
            </div>
            <div className="fs-16 rst-desc">
              {props?.item?.featureAbout !== null ? (
                <div>
                  {parse(
                    anchorme({
                      input: (props?.item?.featureAbout).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="d-flex">
            <Button
              id={props.index}
              className="addButtons"
              onClick={() => props.handleMinus(props.index, props.itemNumber)}
              style={{
                backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
                cursor: props?.isVenueEndDateDisable || props?.whitelabel === true ? 'default' : 'pointer',
              }}
              disabled={props?.isVenueEndDateDisable || props?.whitelabel === true ? true : false}>
              -
            </Button>
            <input
              value={props.count[props.index]}
              onChange={props.handleInputChange}
              onFocus={handleInputFocus}
              className="addButtonInput h-45"
              type="number"
              placeholder=""
              name={props.index}
              disabled={props?.isVenueEndDateDisable || props?.whitelabel === true}
            />
            <Button
              id={props.index}
              className="addButtons"
              onClick={() => props.handleAdd(props.index, props.itemNumber)}
              style={{
                backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
                cursor: props?.isVenueEndDateDisable || props?.whitelabel === true ? 'default' : 'pointer',
              }}
              disabled={props?.isVenueEndDateDisable || props?.whitelabel === true ? true : false}>
              +
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketsList;
