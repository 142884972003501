import React, { useState, useEffect, useContext } from 'react';
import { Collapse, Modal, ModalBody, Button, Col, Row } from 'reactstrap';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { images } from '../../../../../../library/common/constants/ImageConstants';
import CarouselSlider from '../../../../../UserProfile/Frame/WinLoss/Frame/WinLossModal/Slider';
import axiosInstance from '../../../../../../main/axios';
import { fetchFromStorage } from '../../../../../../utility';
import { URLS } from '../../../../../../library/common/constants/UrlConstants';
import { identifiers } from '../../../../../../library/common/constants/IdentifierConstants';
import { EventEmitter } from '../../../../../../library/common/constants/event';
import { setApiMessage } from '../../../../../../library/common/constants/function';
import PageLoader from '../../../../../../library/common/components/PageLoader';
import { ReactComponent as StartBid } from '../../../../../../resources/images/startBid.svg';
import { ReactComponent as CurrentBid } from '../../../../../../resources/images/currentBid.svg';
import { ReactComponent as BidIncrement } from '../../../../../../resources/images/bidIncrement.svg';
import BiddingAmountCount from './BiddingAmountCount';
import LogoutModal from 'modules/EventDetails/Frames/Raffle/LogoutModal';
import DeleteFeatureModal from 'modules/Events/MyEvents/frames/Tickets/frame/DeleteFeatureModal';
import { SocketContext } from '../../../../../../main/context/socket';
import { saveToStorage } from 'library/utilities/Storage';
import { CurrencyFormat } from 'utility';
import '../../../../../UserProfile/Frame/WinLoss/styles.scss';
import './styles.scss';

const EventDetailsAuctionModal = ({
  itemFollowing_isToggle,
  itemFollowing_toggle,
  isfromWinningLot,
  isGuestCheckout,
  makePayment_toggle,
  singleItem,
  categoryList,
  currency,
  currencySymbol,
  eventDetailsData,
  auctionFeatureData,
  buyitNowObj,
  userCards,
  handleSetBuyItNow,
  isLoggedIn,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  var eventID = params?.id;
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [collapse, setCollapse] = useState(true);
  const [count, setCount] = useState(singleItem?.startBid);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [isloginToggle, setisloginToggle] = useState(false);
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [bidButtonStatus, setBidButtonStatus] = useState(false);
  const [bidType, setBidType] = useState('');

  // Fetch user details from storage
  var userDetails = fetchFromStorage(identifiers?.userDetail);

  // Function to toggle collapse
  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  // useEffect to update the bidding count and category when `singleItem` changes
  useEffect(() => {
    setCount(
      singleItem?.currentBid === 0
        ? singleItem?.startBid
        : Number((singleItem?.currentBid + singleItem?.bidIncrement).toFixed(2)),
    );
    var category = categoryList?.filter(item => item.id === singleItem?.categoryId);
    setCategory(category && category[0]?.categoryName);
  }, [singleItem]);

  // Increase bid by bid increment
  const handleAddBidCount = () => {
    setCount(Number((count + singleItem?.bidIncrement).toFixed(2)));
  };

  // Decrease bid with conditions based on current or starting bid
  const handleMinusBidCount = () => {
    if (singleItem?.currentBid === 0) {
      if (count > singleItem?.startBid) {
        setCount(Number((count - singleItem?.bidIncrement).toFixed(2)));
      }
    } else {
      if (count > singleItem?.currentBid + singleItem?.bidIncrement) {
        setCount(Number((count - singleItem?.bidIncrement).toFixed(2)));
      } else {
        setCount(singleItem?.currentBid + singleItem?.bidIncrement);
      }
    }
  };

  // Handle login redirection
  const handleRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'auction',
        eventId: eventID,
      },
    });
  };

  // Toggle payment modal
  const handlePaymentModal = () => {
    setPaymentToggle(!paymentToggle);
  };

  // Handle bid submission logic based on user type
  const handleBidNow = async () => {
    setBidType('bidNow');
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setisloginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        setLoading(true);

        const requestData = {
          bidAmount: count,
        };
        try {
          const { status } = await axiosInstance.post(URLS.createBid(singleItem?.id), requestData);
          if (status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_SUCCESS);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          if (err?.response?.data?.msg === 'ERR_BIDDING_ALREADY_EXISTS') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_OUTBID);
          } else if (err?.response?.data?.msg === 'ERR_BIDDING_INTERVAL_MISMATCH') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_INTERVAL_MISMATCH);
          } else if (err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED') {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        setPaymentToggle(!paymentToggle);
      } else {
        setisloginToggle(!isloginToggle);
        setBidButtonStatus(true);
        itemFollowing_toggle();
      }
    }
  };

  // Handle direct Buy It Now action for logged-in users
  const handleLoginUserBuyItNow = async () => {
    if (isLoggedIn && userDetails?.userType === 'normal') {
      setLoading(true);
      const requestData = {
        eventId: eventID,
      };
      try {
        const { status } = await axiosInstance.put(URLS.buyItNow(singleItem?.id), requestData);
        if (status === 200) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_BID_SUCCESSFULLY);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventID,
            },
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.errCode === 'ERR_CART_ITEM_ALREADY_EXISTS') {
          setApiMessage('error', OKTION_VARIABLE?.OKTION_BIDDING_CARTITEM_EXIST);
        } else {
          setApiMessage('error', err?.response?.data?.msg);
        }
      }
    } else if (isLoggedIn && userDetails?.userType === 'guest') {
      handleGuestBuyitNow();
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    } else {
      setisloginToggle(!isloginToggle);
      itemFollowing_toggle();
    }
  };

  // Handle setting the maximum bid for logged-in users
  const handleSetMaxBid = async () => {
    setBidType('bidMax');
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setisloginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        setLoading(true);
        const requestData = {
          myMaxBid: count,
        };
        try {
          const { status } = await axiosInstance.post(URLS.createBid(singleItem?.id), requestData);
          if (status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_SET_MAX_BID);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          if (
            err?.response?.data?.errCode === 'ERR_BIDDING_ALREADY_EXISTS' ||
            err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED'
          ) {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        setPaymentToggle(!paymentToggle);
      } else {
        setisloginToggle(!isloginToggle);
        setBidButtonStatus(true);
        itemFollowing_toggle();
      }
    }
  };

  // Add guest Buy It Now item to checkout and navigate to guest checkout page
  const handleGuestBuyitNow = () => {
    updateGuestCardList(buyitNowObj);
    navigate('/guest-checkout', {
      state: {
        eventId: params?.id,
      },
    });
  };

  // Update guest card list and dispatch event for updating guest checkout
  const updateGuestCardList = data => {
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    if (data?.constructor === Array) {
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      guestCheckoutData.push(data);
    }

    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  return (
    <>
      <div>
        <Modal
          size="lg"
          className="customModal add-price-modal winLossModal event-details-auctionmodal"
          isOpen={itemFollowing_isToggle}>
          <div className="text-end">
            <img className="cursor-pointer" onClick={itemFollowing_toggle} src={images.close} alt="" />
          </div>
          <ModalBody>
            <div className="modal-auctionBody">
              {loading && <PageLoader />}
              <CarouselSlider images={singleItem?.images} />
              <div className="text-center mt-2 fs-16">
                <h4 className="mt-2 mb-2">{singleItem?.itemName}</h4>
                <p className="mb-2 fs-16">{singleItem?.lotNumber}</p>
                <p className="mb-2 fs-16">
                  {singleItem?.providedBy && singleItem?.providedBy === 'sponsoredBy'
                    ? OKTION_VARIABLE?.OKTION_SPONSOR_BY
                    : singleItem?.providedBy === 'donatedBy'
                      ? OKTION_VARIABLE?.OKTION_DONATION_BY
                      : OKTION_VARIABLE?.OKTION_PROVIDED_BY}{' '}
                  {singleItem?.itemProvidedByName}
                </p>
              </div>
              <div className="d-flex align-items-center flex-md-column">
                {!singleItem?.winner?.isAnonymousContribution && (
                  <div>
                    {singleItem?.winner !== null &&
                    singleItem?.winner?.userProfilePicture !== null &&
                    singleItem?.winner?.userProfilePicture !== '' &&
                    singleItem?.winner?.firstName.toLowerCase() !== 'anonymous contributor' ? (
                      <img className={'userProfilePicture'} src={singleItem?.winner?.userProfilePicture} alt="user" />
                    ) : (
                      <div className={'circle'}></div>
                    )}
                  </div>
                )}
                {singleItem?.winner !== null ? (
                  <p className="winningText fs-16 mt-1">
                    {singleItem?.reserveMet
                      ? OKTION_VARIABLE?.OKTION_WINNING_BIDDER
                      : OKTION_VARIABLE?.OKTION_HIGHEST_BIDDER}{' '}
                    :{' '}
                    {singleItem?.reserveMet
                      ? `${singleItem?.winner?.firstName && singleItem?.winner?.firstName} ${
                          singleItem?.winner?.lastName ? singleItem?.winner?.lastName : ''
                        }`
                      : OKTION_VARIABLE?.OKTION_RESERVE_NOT_MET}
                  </p>
                ) : (
                  <p className="winningText fs-16 mt-1">{`${OKTION_VARIABLE?.OKTION_WINNING_BIDDER} : ${OKTION_VARIABLE?.OKTION_NO_CURRENT_WINNER}`}</p>
                )}{' '}
              </div>
              <div className="d-flex sm-d-flex box mb-3">
                <div className="bid-details">
                  <p className="mb-2">
                    <StartBid fill={eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : ''} />
                  </p>
                  <h5 className="mb-1">{OKTION_VARIABLE?.OKTION_STARTING_BID}</h5>
                  <h5
                    className="fw-normal colorYellow text-amount-auction"
                    style={{
                      color: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                    }}>
                    <CurrencyFormat
                      value={singleItem?.startBid !== null ? singleItem?.startBid : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={`${currency} ${currencySymbol}`}
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </h5>
                </div>
                <div className="bid-details">
                  <p className="mb-2">
                    <BidIncrement fill={eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : ''} />
                  </p>
                  <h5 className="mb-1">{OKTION_VARIABLE?.OKTION_INCREMENT}</h5>
                  <h5
                    className="fw-normal colorYellow text-amount-auction"
                    style={{
                      color: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                    }}>
                    <CurrencyFormat
                      value={singleItem?.bidIncrement !== null ? singleItem?.bidIncrement : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={`${currency} ${currencySymbol}`}
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </h5>
                </div>
                <div className="bid-details">
                  <p className="mb-2">
                    <CurrentBid fill={eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : ''} />
                  </p>
                  <h5 className="mb-1">{OKTION_VARIABLE?.OKTION_CURRENT_BID}</h5>
                  <h5
                    className="fw-normal colorYellow text-amount-auction"
                    style={{
                      color: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                    }}>
                    <CurrencyFormat
                      value={singleItem?.currentBid !== null ? Number(singleItem?.currentBid).toFixed(2) : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={`${currency} ${currencySymbol}`}
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </h5>
                </div>{' '}
              </div>
              <Row>
                {/* About lot collapse */}
                <Col sm="12">
                  <h5 onClick={handleCollapse} className="cursor-pointer">
                    {OKTION_VARIABLE?.OKTION_ABOUT_LOT}{' '}
                    <img className={`ms-3 arrowReverse ${!collapse && 'down'}`} src={images.uppArraow} alt="" />
                  </h5>
                  <Collapse isOpen={collapse}>
                    <div className="mt-3 mb-3">
                      {singleItem?.itemAbout !== null && singleItem?.itemAbout !== undefined ? (
                        <div>
                          {parse(
                            anchorme({
                              input: singleItem?.itemAbout.toString().replace(/\n/g, '<br/>'),
                              options: {
                                attributes: {
                                  target: '_blank',
                                  class: 'detected',
                                },
                              },
                            }),
                          )}{' '}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    {category && (
                      <div className="mt-3 mb-3">
                        <p className="fw-500 fs-16">{OKTION_VARIABLE?.OKTION_LOT_CATEGORIES}</p>
                        <p className="fs-16">{category}</p>
                      </div>
                    )}

                    <div className="mt-3 mb-3 bottomLine">
                      {singleItem?.postageAndHandeling && (
                        <>
                          <p className="fw-500 fs-16">{OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING}</p>
                          <p className="fs-16">
                            <CurrencyFormat
                              value={
                                singleItem?.postageAndHandeling !== null ? singleItem?.postageAndHandeling : '0.00'
                              }
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={`${currency} ${currencySymbol}`}
                              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                              decimalScale="2"
                              fixedDecimalScale={true}
                            />
                          </p>{' '}
                        </>
                      )}
                      {singleItem?.postageHandlingNote && (
                        <div className="mt-3">
                          <p className="fw-500 fs-16">{OKTION_VARIABLE?.OKTION_POSTAGE_NOTES}</p>
                          <span>{singleItem?.postageHandlingNote}</span>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </Col>
                {!isfromWinningLot ? (
                  <>
                    {/* Event cards bidding button section */}
                    <BiddingAmountCount
                      handleSetMaxBid={handleSetMaxBid}
                      handleBidNow={handleBidNow}
                      handleBuyItNow={handleLoginUserBuyItNow}
                      handleAdd={handleAddBidCount}
                      handleMinus={handleMinusBidCount}
                      count={count}
                      currency={currency}
                      currencySymbol={currencySymbol}
                      singleItem={singleItem}
                      setQuickBid={false}
                      auctionFeatureData={auctionFeatureData}
                      eventDetailsData={eventDetailsData}
                    />
                  </>
                ) : (
                  <Button
                    style={{ width: '100%' }}
                    disabled={singleItem?.paymentStatus === false}
                    className="app-button blue-button mt-2 h-45"
                    onClick={makePayment_toggle}>
                    {singleItem?.markAsPaid === true
                      ? OKTION_VARIABLE?.OKTION_LOTSOLD
                      : OKTION_VARIABLE?.OKTION_MAKEPAYMENT}
                  </Button>
                )}
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </div>

      {/* Card details required modal */}
      <LogoutModal
        isToggle={isloginToggle}
        toggle={() => setisloginToggle(!isloginToggle)}
        data={singleItem}
        handleClick={handleRedirect}
        isGuestCheckout={isGuestCheckout}
        isfromWinningLot={isfromWinningLot}
        singleItem={buyitNowObj}
        guestUserStatus={bidButtonStatus}
        bidType={bidType}
        bidCount={count}
      />

      {/* Card details required modal */}
      <DeleteFeatureModal
        isToggle={paymentToggle}
        toggle={handlePaymentModal}
        handleDeleteFeature={() => navigate('/user-profile?user-profile-card-details')}
        FROM_WHERE="contribution"
      />
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(EventDetailsAuctionModal);
