import React from 'react';
import '../../styles.scss';
import { identifiers } from '../../../../../../../../../library/common/constants/IdentifierConstants';

const AuctionWallPlatinumSponser = ({ itemValue, images }) => {
  return (
    <div className="auctionWallPlatinumSponsor mt-4 text-center">
      <h4 className="fw-500 mb-3">{itemValue}</h4>
      <div className="d-flex justify-content-center flex-wrap">
        {images &&
          images?.map((item, i) => (
            <div key={i} className="sponsor-box">
              <img src={identifiers.imageUrlConstant + '' + item?.imagePath} alt={item?.imageName} className="mb-4" />
              {item?.sponsorsName && <span className="">{item?.sponsorsName}</span>}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AuctionWallPlatinumSponser;
