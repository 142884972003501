import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Col, InputGroup, Row } from 'reactstrap';
import moment from 'moment';
import EventTimeDetails from '../EventTimeDetails';
import LogoutModal from '../Raffle/LogoutModal';
import PageLoader from '../../../../library/common/components/PageLoader';
import { SocketContext } from '../../../../main/context/socket';
import { images } from '../../../../library/common/constants/ImageConstants';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import axiosInstance from '../../../../main/axios';
import { setApiMessage } from '../../../../library/common/constants/function';
import { fetchFromStorage, saveToStorage, CurrencyFormat } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';

// Custom hook for forcing component updates
const useForceUpdate = () => {
  let [value, setState] = useState(true);
  return () => setState(!value);
};

const Donations = ({ eventFeatures, eventDetails, whitelabel, timeZone, isLoggedIn }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const params = useParams();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const [isToggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [donationAmountArray, setDonationAmountArray] = useState([]);
  const [donationAmountArrayOther, setDonationAmountArrayOther] = useState([]);
  const [cartItem, setClickItemData] = useState('');
  var eventId = params?.id;

  const userDetail = fetchFromStorage(identifiers?.userDetail);

  // Initialize donation amounts on component mount
  useEffect(() => {
    initializeDonationAmounts();
  }, [eventFeatures]);

  // Initializes donation amounts from event features
  const initializeDonationAmounts = () => {
    if (!eventFeatures) return;

    const donationFeatureData = eventFeatures?.find(item => item?.type === 'donation');
    const donations = donationFeatureData?.donations || [];

    const { regular, other } = donations.reduce(
      (acc, element) => {
        const enrichedElement = {
          ...element,
          count: 1,
          checked: false,
          otherAmount: '',
        };

        if (element?.amount === null) {
          acc.other.push(enrichedElement);
        } else {
          acc.regular.push(enrichedElement);
        }
        return acc;
      },
      { regular: [], other: [] },
    );

    setDonationAmountArray(regular);
    setDonationAmountArrayOther(other);
  };

  const toggle = () => {
    setToggle(!isToggle);
  };

  // Handles user navigation to login
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'donation',
        eventId: eventId,
      },
    });
  };

  // Validates donation form
  const handleValidate = () => {
    const otherDonation = donationAmountArrayOther[0];
    if (otherDonation?.checked && (!otherDonation.otherAmount || otherDonation.otherAmount === 0)) {
      setErrorMessage(true);
      return false;
    }
    return true;
  };

  // Handles adding items to cart
  const handleAddToCart = async eventId => {
    if (isLoggedIn && userDetail?.userType === 'normal') {
      // Prepares cart data for checkout
      let donationAmountArrayClone = donationAmountArray;
      let donationAmountArrayOtherClone = donationAmountArrayOther[0];
      var cartArrayClone = donationAmountArrayClone?.filter(item => item?.checked !== false);
      let cartArray = [];
      cartArrayClone?.map(element => {
        let obj = {
          type: 'donation',
          eventId: eventId,
          referenceId: null,
          quantity: element?.count,
          purchaseReferenceId: element?.id,
          isRaffleTicket: false,
        };
        cartArray.push(obj);
      });

      if (donationAmountArrayOtherClone?.checked) {
        if (donationAmountArrayOtherClone?.otherAmount !== '') {
          let obj2 = {
            type: 'donation',
            eventId: eventId,
            referenceId: null,
            isRaffleTicket: false,
            quantity: 1,
            purchaseReferenceId: donationAmountArrayOtherClone?.id,
            donationAmount: donationAmountArrayOtherClone?.otherAmount,
          };
          cartArray.push(obj2);
        }
      }
      // Check validation
      if (handleValidate()) {
        // Processes checkout for normal users
        setLoading(true);
        try {
          const { status } = await axiosInstance.post(URLS.addToCart, cartArray);
          if (status === 201 || status === 200) {
            setLoading(false);
            navigate('/checkout', {
              state: {
                FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
                eventId: eventId,
              },
            });
          }
        } catch (err) {
          setLoading(false);
          setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
        }
      }
    } else if (isLoggedIn && userDetail?.userType === 'guest') {
      // Processes checkout for guest users
      updateGuestCardList();
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    } else {
      if (handleValidate()) {
        handleSetCartData(eventId);
        setToggle(true);
      }
    }
  };

  // Handle set data in cart state for normal user
  const handleSetCartData = eventId => {
    let donationAmountArrayClone = donationAmountArray;
    let donationAmountArrayOtherClone = donationAmountArrayOther[0];
    var cartArrayClone = donationAmountArrayClone?.filter(item => item.checked !== false);
    let cartArray = [];
    cartArrayClone?.map(element => {
      let obj = {
        type: 'donation',
        quantity: element?.count,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: element?.name,
        amount: element?.amount,
        purchaseReferenceId: element?.id,
        isRaffleTicket: false,
        donationAmount: element?.amount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: element,
        cardCharedAmount: 0,
      };
      cartArray.push(obj);
    });

    if (donationAmountArrayOtherClone?.checked === true) {
      if (donationAmountArrayOtherClone?.otherAmount !== '') {
        let obj2 = {
          type: 'donation',
          quantity: 1,
          referenceId: null,
          subscriptionName: null,
          subscriptionPrice: null,
          name: donationAmountArrayOtherClone?.name,
          amount: donationAmountArrayOtherClone.otherAmount,
          purchaseReferenceId: donationAmountArrayOtherClone.id,
          isRaffleTicket: false,
          donationAmount: donationAmountArrayOtherClone.otherAmount,
          eventId: eventId,
          currency: eventDetails?.currency?.abbreviation,
          symbol: eventDetails?.currency?.symbol,
          event: eventDetails,
          eventName: eventDetails?.eventName,
          digiCommission: 0,
          itemDetails: donationAmountArrayOtherClone,
          cardCharedAmount: 0,
        };
        cartArray.push(obj2);
      }
    }

    setClickItemData(cartArray);
  };

  // Handle set data in eventemitter for guest user
  const updateGuestCardList = () => {
    let donationAmountArrayClone = donationAmountArray;
    let donationAmountArrayOtherClone = donationAmountArrayOther[0];
    var cartArrayClone = donationAmountArrayClone?.filter(item => item?.checked !== false);
    let cartArray = [];
    cartArrayClone?.map(element => {
      let obj = {
        type: 'donation',
        quantity: element?.count,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: element?.name,
        amount: element?.amount,
        purchaseReferenceId: element?.id,
        isRaffleTicket: false,
        donationAmount: element?.amount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: element,
        cardCharedAmount: 0,
      };
      cartArray.push(obj);
    });

    if (donationAmountArrayOtherClone?.checked) {
      if (donationAmountArrayOtherClone?.otherAmount !== '') {
        let obj2 = {
          type: 'donation',
          quantity: 1,
          referenceId: null,
          subscriptionName: null,
          subscriptionPrice: null,
          name: donationAmountArrayOtherClone?.name,
          amount: donationAmountArrayOtherClone?.otherAmount,
          purchaseReferenceId: donationAmountArrayOtherClone?.id,
          isRaffleTicket: false,
          donationAmount: donationAmountArrayOtherClone?.otherAmount,
          eventId: eventId,
          currency: eventDetails?.currency?.abbreviation,
          symbol: eventDetails?.currency?.symbol,
          event: eventDetails,
          eventName: eventDetails?.eventName,
          digiCommission: 0,
          itemDetails: donationAmountArrayOtherClone,
          cardCharedAmount: 0,
        };
        cartArray.push(obj2);
      }
    }
    let singleItem = cartArray;
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (singleItem?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...singleItem];
    } else if (singleItem) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(singleItem);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  // Hnadle check/uncheck donation amount
  const handleCheckedChange = (typeEdit, isMinus, count, id) => {
    let donationAmountArrayClone = donationAmountArray;
    if (typeEdit === 1) {
      donationAmountArrayClone?.map(element => {
        if (element?.id === id) {
          element.checked = count;
        }
      });
    } else if (typeEdit === 2) {
      donationAmountArrayClone?.map(element => {
        if (element?.id === id) {
          element.count = Number(count);
        }
      });
    } else if (typeEdit === 3) {
      donationAmountArrayClone?.map(element => {
        if (element?.id === id) {
          if (isMinus) {
            element.count = element?.count === 1 ? 1 : element?.count - 1;
          } else {
            element.count = element?.count + 1;
          }
        }
      });
    }
    setDonationAmountArray(donationAmountArrayClone);
    forceUpdate();
  };

  // Hnadle check/uncheck other donation amount
  const handleOtherCheckedChange = (typeEdit, count) => {
    let donationAmountArrayClone = donationAmountArrayOther[0];

    if (typeEdit === 1) {
      donationAmountArrayClone['checked'] = count;
      setErrorMessage(false);
    } else if (typeEdit === 2) {
      donationAmountArrayClone['otherAmount'] = Number(count);
      setErrorMessage(false);
    }
    setDonationAmountArrayOther([donationAmountArrayClone]);
    forceUpdate();
  };

  // Handle onkeydown to check value
  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  var donationFeatureData = eventFeatures?.filter(item => item?.type === 'donation');
  var currency = eventDetails?.currency?.abbreviation;
  var symbol = eventDetails?.currency?.symbol;
  var buttonActive = true;
  //  set active/inactive to cart button
  donationAmountArray?.map(element => {
    if (element.checked === true) {
      buttonActive = false;
    }
  });
  donationAmountArrayOther?.map(element => {
    if (element.checked === true) {
      buttonActive = false;
    }
  });

  return (
    <Row className="res-org-donation-amt">
      <Col lg="9" md="8" sm="12">
        <h4 className="pb-3 pt-3">{OKTION_VARIABLE?.OKTION_DONATION_AMOUNTS}</h4>
        <div className="priceCircle">
          {donationAmountArray && donationAmountArray?.length > 0 && (
            <Row>
              {donationAmountArray
                ?.filter(element => {
                  return element?.amount !== 0;
                })
                ?.map((item, index) => (
                  <Col
                    lg="3"
                    md="6"
                    sm="6"
                    key={index}
                    className="text-center mb-4"
                    style={{
                      pointerEvents: donationFeatureData?.[0]?.isActive === false ? 'none' : '',
                      opacity: donationFeatureData?.[0]?.isActive === false ? '0.4' : '1',
                    }}>
                    <div
                      className="circle m-auto"
                      style={{
                        borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                      }}>
                      <h2>
                        <CurrencyFormat
                          value={item?.count * item?.amount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`${eventDetails?.currency != null ? currency : ''} ${
                            eventDetails?.currency != null ? symbol : ''
                          }`}
                          thousandSpacing={symbol === '₹' ? '2s' : '3'}
                          decimalScale="2"
                          fixedDecimalScale={true}
                        />
                      </h2>
                    </div>
                    <span className="pt-4 mt-1 fw-500 text-start ps-4 d-block">{item?.name}</span>
                    <div className="text-start pt-2 ps-4 mt-0 form-group mb-0">
                      <div className="custom-checkbox form-check ">
                        <input
                          className="form-check-input form-check-label"
                          type="checkbox"
                          id={index}
                          name={index}
                          value={item?.id}
                          onClick={event => handleCheckedChange(1, true, event.target.checked, item.id)}
                        />
                        <label className="form-check-label">
                          <CurrencyFormat
                            value={item?.amount || '0.00'}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={`Donate ${
                              eventDetails?.currency != null ? currency : ''
                            } ${eventDetails?.currency != null ? symbol : ''}`}
                            thousandSpacing="2s"
                            decimalScale="2"
                            fixedDecimalScale={true}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          )}

          {donationAmountArrayOther && donationAmountArrayOther.length > 0 && (
            <div
              className="mt-5  ps-4"
              style={{
                pointerEvents: donationFeatureData?.[0]?.isActive === false ? 'none' : '',
                opacity: donationFeatureData?.[0]?.isActive === false ? '0.4' : '1',
              }}>
              <div className="custom-checkbox form-check ">
                <input
                  type="checkbox"
                  name="checkbox2"
                  onClick={event => handleOtherCheckedChange(1, event.target.checked)}
                  className="form-check-input form-check-label"
                  id="other"
                />
                <label className="form-check-label">Other amount</label>
              </div>
              <InputGroup>
                <input
                  className="h-45 mb-3 ps-5 input_field-style form-control"
                  onChange={e => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    handleOtherCheckedChange(2, e.target.value);
                  }}
                  pattern="[0-9]*"
                  type="number"
                  onKeyDown={onKeyPress}
                  placeholder={OKTION_VARIABLE?.OKTION_ENTER_AMOUNT}
                  onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                />
                <span className="span-style"> {currency}</span>
                {errorMessage === true && <p className="text-danger">{OKTION_VARIABLE?.OKTION_PLEASE_ENTER_AMOUNT}</p>}
              </InputGroup>{' '}
            </div>
          )}
          {((donationAmountArray && donationAmountArray?.length > 0) ||
            (donationAmountArrayOther && donationAmountArrayOther?.length > 0)) && (
            <Button
              disabled={buttonActive || whitelabel === true ? true : false}
              onClick={() => handleAddToCart(donationFeatureData?.[0].eventId)}
              className={
                eventDetails?.primaryColour ? 'btn-block  bigButton ms-3' : 'main-button btn-block mt-2 bigButton ms-3'
              }
              style={{
                backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          )}
        </div>
      </Col>
      <Col lg="3" md="4" sm="12" className="pt-4 mt-3 mb-3">
        {timeZone !== undefined && (
          <>
            {/* Event right side details */}
            <EventTimeDetails
              isLocation
              title={OKTION_VARIABLE?.OKTION_DONATION_FINISH_DATE}
              img={images.watch}
              address={moment(donationFeatureData[0]?.endTime).format('DD/MM/YYYY hh:mm A')}
            />
          </>
        )}
      </Col>
      {/* Login required modal */}
      <LogoutModal
        isToggle={isToggle}
        toggle={toggle}
        handleClick={handleLoginRedirect}
        isGuestCheckout
        singleItem={cartItem}
      />
      {loading && <PageLoader />}
    </Row>
  );
};
const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Donations);
