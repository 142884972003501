import React, { useState, useEffect, useRef, useContext } from 'react';
import Slider from 'react-slick';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import parse from 'html-react-parser';
import anchorme from 'anchorme';

import TicketsCard from '../../../UserProfile/Frame/ContributionHistory/Frame/TicketsCard';
import RaffleSlider from './RaffleSlider';
import EventTimeDetails from '../EventTimeDetails';
import RaffleAddToCard from './RaffleAddToCard';
import LogoutModal from './LogoutModal';
import TicketDataModal from '../Tickets/TicketDataModal';
import PageLoader from 'library/common/components/PageLoader';

import { images } from '../../../../library/common/constants/ImageConstants';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { fetchFromStorage, saveToStorage } from 'utility';
import axiosInstance from '../../../../main/axios';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../UserProfile/Frame/ContributionHistory/styles.scss';

const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const Raffle = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const params = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();
  const myRef = useRef(null);

  const [isToggle, setToggle] = useState(false);
  const [raffleTicket, setRaffleTicket] = useState([]);
  const [ticketItemData, setTicketItemData] = useState('');
  const [isToggleModal, setToggleModalValue] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const [singleItem, setClickItemData] = useState('');
  const [isabout, setIsAbout] = useState(true);
  const [isLoader, setIsLoader] = useState(false);

  var eventId = params?.id;

  useEffect(() => {
    // Handle window resize
    const updateSize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    // Fetch ticket data when user is logged in
    props?.isLoggedIn && fetchTicketPurchaseList();
  }, [props?.isLoggedIn]);

  // Calculate slides to show based on screen size
  const getSlideCount = size => {
    if (size > 1279) return 4;
    if (size > 959) return 3;
    if (size > 599) return 2;
    return 1;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: getSlideCount(size),
    slidesToScroll: 1,
  };

  // Handle login redirect
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'raffle',
        eventId: eventId,
      },
    });
  };

  // Handle mange login modal and redirect guest checkout screen
  const handleLoginModal = data => {
    if (!props?.isLoggedIn) {
      setToggle(true);
    } else {
      updateGuestCardList(data);
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    }
  };

  // Update guest card list and dispatch event for updating guest checkout
  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  // fetch tickets list
  const fetchTicketPurchaseList = async () => {
    try {
      setIsLoader(true);
      const { status, data } = await axiosInstance.get(URLS.getTicketPurchase(eventId, true));
      if (status === 200 || status === 304) {
        setRaffleTicket(data?.data?.tickets);
        // setTotalCount(data?.data?.count);
        // setOffset(page);
        setIsLoader(false);
      }
    } catch (err) {
      setIsLoader(false);
    }
  };

  const handleTicketItemOnClick = item => {
    setTicketItemData(item);
    setToggleModalValue(true);
  };

  const executeScroll = () => scrollToRef(myRef);

  // Filter raffle feature data
  var raffleFeatureData = props?.eventFeatures?.filter(item => item?.type === 'raffle');

  return (
    <>
      {isLoader && <PageLoader />}
      <Row className="res-raffale-prz">
        <Col md="8" lg="9">
          <div>
            {raffleFeatureData[0]?.featureName && (
              <div className="d-flex align-items-center justify-content-between">
                <h4
                  className="pt-4 mb-3 cursor-pointer"
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}>
                  {raffleFeatureData[0]?.featureName}
                </h4>
                <img
                  className={!isabout ? 'closed cursor-pointer' : 'cursor-pointer'}
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}
                  src={images?.uppArraow}
                  alt=""
                  width="15px "
                />
              </div>
            )}
            {raffleFeatureData[0]?.featureAbout != null && (
              <Collapse isOpen={isabout}>
                <div className="fs-16 font-Segoe_UI">
                  {parse(
                    anchorme({
                      input: (raffleFeatureData[0]?.featureAbout).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
                </div>
              </Collapse>
            )}
          </div>
        </Col>
        <Col md="4" lg="3" className="pt-4 mt-3 mb-3">
          {props?.timeZone !== undefined && (
            <EventTimeDetails
              isLocation
              title={OKTION_VARIABLE?.OKTION_DRAW_DATE}
              img={images.watch}
              address={moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a')}
            />
          )}
          {props?.eventDetails?.chanceToWinStatus === 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}
              isLocation
              img={images.winIcon}
              address={`${raffleFeatureData[0]?.chanceToWin} ${OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}`}
            />
          )}
        </Col>

        {/* Do not remove this code bcz it's used for print */}
        <div style={{ display: 'none' }}>
          <div ref={componentRef} className="m-2">
            <div className="print-container" style={{ margin: '0', padding: '0' }}>
              {raffleTicket?.map((item, index) => (
                <React.Fragment key={index}>
                  <TicketsCard
                    isBarcode={false}
                    isTicket={true}
                    ticketData={item}
                    showImage={true}
                    handleItemClick={handleTicketItemOnClick}
                    imageUrl={props?.eventDetails?.eventImages[0].name}
                    printImage
                    raffleDrawDate={
                      raffleFeatureData ? moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a') : null
                    }
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <Col sm="12">
          <div className="d-flex justify-content-between align-items-center pt-4 mt-3 mb-1 ps-2 pe-2">
            {raffleTicket && raffleTicket?.length > 0 && (
              <>
                <h4 className="mb-0">{OKTION_VARIABLE?.OKTION_YOUR_RAFFLE}</h4>
                <ReactToPrint
                  pageStyle={pageStyle}
                  trigger={() => <p className="fw-500 fs-16 cursor-pointer">Print Tickets</p>}
                  content={() => componentRef.current}
                />
              </>
            )}
          </div>
          {raffleTicket?.length > 0 ? (
            <Slider {...settings}>
              {raffleTicket?.map((item, index) => (
                <React.Fragment key={index}>
                  <TicketsCard
                    isBarcode={false}
                    isTicket={true}
                    ticketData={item}
                    showImage={true}
                    handleItemClick={handleTicketItemOnClick}
                    imageUrl={props?.eventDetails?.eventImages[0].name}
                    raffleDrawDate={
                      raffleFeatureData ? moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a') : null
                    }
                  />
                </React.Fragment>
              ))}
            </Slider>
          ) : (
            !isLoader && (
              <div>
                <p className="text-center">No data found</p>
              </div>
            )
          )}
        </Col>
        <Col lg="9" md="8">
          <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_RAFFLE_PRIZE}</h4>
          {raffleFeatureData?.[0]?.rafflePrizes?.map((item, i) => (
            <React.Fragment key={i}>
              <RaffleSlider sliderData={item} index={i} />
            </React.Fragment>
          ))}
        </Col>
      </Row>

      {raffleFeatureData[0]?.ticketBundles.length > 0 && (
        <>
          <Row className="res-raffale-prz direction-reverse">
            <Col sm="12">
              <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_RAFFLE_TICKET_BUNDLES}</h4>{' '}
            </Col>
            {raffleFeatureData[0]?.ticketBundles.map((item, i) => (
              <Col key={i} lg="3" md="6" sm="12" className="mb-4">
                <RaffleAddToCard
                  key={i}
                  ticketName={item.ticketBundleName}
                  price={item.bundlePrize}
                  noTicket={item.ticketQuantity}
                  item={item}
                  eventDetails={raffleFeatureData[0]}
                  handleBtnClick={data => {
                    handleLoginModal(data);
                  }}
                  islogin={props?.isLoggedIn}
                  eventDetailsData={props?.eventDetails}
                  whitelabel={props?.whitelabel}
                  setClickItemData={data => {
                    setClickItemData(data);
                  }}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
      <div className="create-button-view d-flex justify-content-center">
        <Button
          onClick={executeScroll}
          className="main-button btn-block fw-500 h-45 raffle-buy-button"
          disabled={raffleFeatureData[0]?.isDraw === '1' ? true : false}
          style={{
            backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
            borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
            color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
          }}>
          {OKTION_VARIABLE?.OKTION_BUY_RAFFLE_TICKET}
        </Button>
      </div>
      <div ref={myRef}>
        <Row className="mt-3">
          <Col sm="12">
            <hr />
          </Col>
        </Row>
      </div>
      <LogoutModal
        isToggle={isToggle}
        toggle={() => setToggle(!isToggle)}
        handleClick={handleLoginRedirect}
        singleItem={singleItem}
        isGuestCheckout={true}
      />
      {isToggleModal && (
        <TicketDataModal
          isToggle={isToggleModal}
          toggle={() => setToggleModalValue(!isToggleModal)}
          item={ticketItemData}
          eventDetails={props?.eventDetails}
          raffleDrawDate={
            raffleFeatureData ? moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a') : null
          }
        />
      )}
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(Raffle);
