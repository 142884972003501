import React, { useEffect, useState } from 'react';
import './toogle.scss';
import { EventEmitter } from '../../../../library/common/constants/event';

const ToggleSwitch = props => {
  const [isChecked, setChecked] = useState(props?.defaultValue || false);

  useEffect(() => {
    setChecked(props?.defaultValue || false);
  }, [props.defaultValue]);

  const handleToggle = e => {
    setChecked(isChecked);
    if (props.onChange) {
      props.onChange(!isChecked);
    }
  };
  const resetSwitch = () => {
    setChecked(props?.defaultValue || false);
  };

  useEffect(() => {
    EventEmitter.subscribe('resetSwitchValue', resetSwitch);
    return () => {
      EventEmitter.unsubscribe('resetSwitchValue', resetSwitch); // Clean up the subscription
    };
  }, []);

  return (
    <div className="toggle_switch d-flex">
      <label className="switch m-0">
        <input checked={isChecked} type="checkbox" onChange={handleToggle} disabled={props?.disabled} />
        <span className={`slider round ${props?.disabled ? 'cursor-default opacity-75' : ''}`}></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
